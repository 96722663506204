import React from 'react';
import { Box } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@mui/icons-material/Room';

const MapMarker = () => (
  <Box
    position="absolute"
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="20px"
    height="20px"
    left="5px"
    top="-10px"
    zIndex="9999"
  >
    <RoomIcon fontSize="large" color="error" />
  </Box>
);

class DragableMaps extends React.Component {
  constructor(props) {
    super(props);

    const defaultLat = this.props.latitude || -6.146285190344943;
    const defaultLng = this.props.longitude || 106.81473277420739;
    this.state = {
      center: [defaultLat, defaultLng],
      zoom: 15,
      draggable: true,
      lat: defaultLat,
      lng: defaultLng,
    };
  }

  onChange = ({ center, zoom }) => {
    this.setState(
      {
        lat: center.lat,
        lng: center.lng,
        zoom: zoom,
      },
      () => {
        this.props.onDragEnd({ lat: center.lat, lng: center.lng });
      },
    );
  };

  render() {
    return (
      <Box width="100%" height="400px">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyC2kj2L7wUjVwDrRCP4k5o3RF5ZU5r6nwI',
            language: 'id',
            region: 'id',
          }}
          draggable={this.state.draggable}
          onChange={this.onChange}
          zoom={this.state.zoom}
          center={this.state.center}
        >
          <MapMarker lat={this.state.lat} lng={this.state.lng} />
        </GoogleMapReact>
      </Box>
    );
  }
}

export default DragableMaps;
