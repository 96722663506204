export default function retrieveUserKey(val) {
  let userSK;

  try {
    const payload = JSON.parse(val);
    userSK = payload.id;
  } catch (err) {
    userSK = val;
  }

  return userSK;
}
