import React from 'react';
import debounce from 'lodash.debounce';
import api from 'libs/utils/api';

export const useAutocompleteLocation = () => {
  const [loading, setLoading] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (query) {
      setLoading(true);
      api
        .get(`/location?q=${query}`)
        .then(({ data: res }) => {
          setOptions(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [query]);

  return [options, debounce(setQuery, 1000), loading];
};
