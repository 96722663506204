import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import format from 'date-fns/format';
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Divider,
  FormHelperText,
  FormControlLabel,
  Switch,
  Paper,
  Typography,
  Skeleton
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';

import config from 'config';
import { SecondaryNavigation, Loader, Empty } from 'components';
import occupations from 'pages/User/occupations.json';

import uploadImage from 'libs/utils/uploadImage';
import { getImage } from 'libs/utils/image';
import { useJemaatDetail } from 'libs/hooks/jemaat';
import useToast from 'libs/contexts/toasts';

const EditJemaat = ({ match }) => {
  const toast = useToast();
  const {
    data: user,
    loading,
    onUpdate,
  } = useJemaatDetail(match.params.user_sk);

  const [isUploading, setUploadStatus] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [picturePreview, setPreview] = useState('');

  useEffect(() => {
    register('occupation');
  }, [register]);

  useEffect(() => {
    if (user) {
      reset({
        name: user?.name,
        nickname: user?.nickname,
        mandarin_name: user?.mandarin_name,
        member_reg_no: user?.member_reg_no,
        birth_place: user?.birth_place,
        phone: user?.phone,
        field_of_work: user?.field_of_work,
        is_baptised: user?.is_baptised,
        baptise_church: user?.baptise_church,
        baptise_year: user?.baptise_year,
        available_for_donor: user?.available_for_donor,
        membership: user?.membership,
        age: user?.age,
        blood_rhesus: user?.blood_rhesus,
        blood_type: user?.blood_type,
        church_origin: user?.church_origin,
        date_of_birth: user?.date_of_birth,
        gender: user?.gender,
        join_date: user?.join_date,
        marital_status: user?.marital_status,
        occupation: user?.occupation,
        picture: user?.picture,
      });
    }
  }, [user]); // eslint-disable-line

  const handleUpload = ({ target }) => {
    setUploadStatus(true);
    uploadImage(target.files[0], { folder: config.JEMAAT_FOLDER })
      .then((res) => {
        setValue('picture', res.secure_url);
        setPreview(res.secure_url);
      })
      .catch(() => {
        toast('error', 'Gagal menunggah foto!');
      })
      .finally(() => {
        setUploadStatus(false);
      });
  };

  const onSubmit = (values) => {
    onUpdate(values);
  };

  const { ref: nameRef, ...nameRest } = register('name', { required: true });
  const { ref: nickNameRef, ...nickNameRest } = register('nickname', {
    required: true,
  });
  const { ref: zhNameRef, ...zhNameRest } = register('mandarin_name');
  const { ref: memberNoRef, ...memberNoRest } = register('member_reg_no');
  const { ref: pobRef, ...pobRest } = register('birth_place', {
    required: true,
  });
  const { ref: phoneRef, ...phoneRest } = register('phone');
  const { ref: fowRef, ...fowRest } = register('field_of_work');
  const { ref: baptiseChurchRef, ...baptiseChurchRest } =
    register('baptise_church');
  const { ref: churchOrigRef, ...churchOrigRest } = register('church_origin');
  const isTemp = watch('membership') === 'TEMPORARY';
  const isBaptise = watch('is_baptised');

  if (loading) {
    return (
      <Box margin="auto" maxWidth="550px" padding="16px">
        <Skeleton variant="rectangular" width="auto" height={118} />
        <Skeleton
          variant="rectangular"
          width="auto"
          height={118}
          style={{ margin: '16px 0' }}
        />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <SecondaryNavigation title="Ubah Data Anggota" />
      {loading ? <Loader /> : null}
      {!user && !loading ? (
        <Empty />
      ) : (
        <Box marginX="auto" maxWidth="550px" padding={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper style={{ padding: 16, marginBottom: 16 }}>
              <Typography variant="h6" component="h3" marginBottom={2}>
                Profil Jemaat
              </Typography>

              <Box>
                {picturePreview || user.picture ? (
                  <Box
                    sx={{ justifyContent: 'center', display: 'flex' }}
                    marginBottom={4}
                  >
                    <Avatar
                      variant="circular"
                      sx={{ width: 200, height: 200 }}
                      alt="Uploads"
                      src={getImage(picturePreview || user.picture)}
                    />
                  </Box>
                ) : null}

                <label htmlFor="input-file">
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    {isUploading ? 'Sedang mengunggah...' : 'Upload Foto'}
                  </Button>
                </label>
                <FormHelperText style={{ marginTop: 16 }}>
                  Ukuran foto maksimal 8MB
                </FormHelperText>
                <input
                  accept="image/*"
                  id="input-file"
                  type="file"
                  style={{ visibility: 'hidden' }}
                  onChange={handleUpload}
                  onClick={(e) => (e.target.value = null)}
                />
              </Box>
              <input
                name="picture"
                type="text"
                style={{ display: 'none' }}
                value={picturePreview}
                onChange={() => {}}
                {...register('picture', { required: true })}
              />
              {errors?.picture ? (
                <FormHelperText error style={{ marginBottom: 16 }}>
                  Foto tidak boleh kosong!
                </FormHelperText>
              ) : null}

              <Divider style={{ marginBottom: '16px' }} />

              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <TextField
                  fullWidth
                  name="name"
                  label="Nama"
                  helperText="Nama sesuai KTP"
                  error={errors?.name}
                  defaultValue={user.name}
                  inputRef={nameRef}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...nameRest}
                />
              </FormControl>
              <Box display="flex" justifyContent="space-between">
                <FormControl margin="normal">
                  <TextField
                    name="nickname"
                    label="Nama Panggilan"
                    error={errors?.nickname}
                    defaultValue={user.nickname}
                    inputRef={nickNameRef}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...nickNameRest}
                  />
                </FormControl>

                <FormControl
                  style={{ minWidth: '48%', marginLeft: '16px' }}
                  margin="normal"
                >
                  <TextField
                    name="mandarin_name"
                    label="Nama Mandarin"
                    helperText="Kosongkan jika tidak ada"
                    error={errors?.mandarin_name}
                    defaultValue={user.mandarin_name}
                    inputRef={zhNameRef}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...zhNameRest}
                  />
                </FormControl>
              </Box>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <TextField
                  fullWidth
                  name="member_reg_no"
                  label="No Anggota"
                  helperText="Kosongkan jika tidak ada"
                  error={errors?.member_reg_no}
                  defaultValue={user.member_reg_no}
                  inputRef={memberNoRef}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...memberNoRest}
                />
              </FormControl>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <InputLabel>Jenis Kelamin</InputLabel>
                <Controller
                  render={({ field }) => (
                    <Select {...field} label="Jenis Kelamin">
                      <MenuItem value="MALE">Laki-Laki</MenuItem>
                      <MenuItem value="FEMALE">Perempuan</MenuItem>
                    </Select>
                  )}
                  name="gender"
                  control={control}
                  defaultValue={user.gender || 'FEMALE'}
                />
              </FormControl>
              <Box display="flex" justifyContent="space-between">
                <FormControl margin="normal">
                  <TextField
                    name="birth_place"
                    label="Tempat Lahir"
                    helperText="Tempat lahir sesuai KTP"
                    error={errors?.birth_place}
                    defaultValue={user.birth_place}
                    inputRef={pobRef}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...pobRest}
                  />
                </FormControl>
                <FormControl
                  style={{ minWidth: '48%', marginLeft: '16px' }}
                  margin="normal"
                >
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <MobileDatePicker
                        sx={{
                          minWidth: '48%',
                          marginTop: '16px',
                          marginLeft: '16px',
                        }}
                        label="Tanggal Lahir"
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          onChange(format(value, 'MM/dd/yyyy'))
                        }
                        value={value}
                      />
                    )}
                    name="date_of_birth"
                    control={control}
                    defaultValue={new Date()}
                  />
                </FormControl>
              </Box>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <TextField
                  fullWidth
                  name="phone"
                  label="No. HP/WA"
                  helperText="Tambahkan kode negara. (Contoh: 628123456789)"
                  type="number"
                  defaultValue={user.phone}
                  inputRef={phoneRef}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...phoneRest}
                />
              </FormControl>
            </Paper>
            <Paper style={{ padding: 16, marginBottom: 16 }}>
              <Typography
                variant="h6"
                component="h3"
                style={{ marginBottom: 16 }}
              >
                Data Lain
              </Typography>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <Autocomplete
                  fullWidth
                  options={occupations}
                  getOptionLabel={(option) => {
                    return option;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Pilih Pekerjaan" />
                  )}
                  onSelect={({ target }) =>
                    setValue('occupation', target.value)
                  }
                  defaultValue={user.occupation || 'Belum / Tidak Bekerja'}
                />
              </FormControl>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <TextField
                  fullWidth
                  name="field_of_work"
                  label="Bidang Pekerjaan/Perusahaan"
                  defaultValue={user.field_of_work}
                  inputRef={fowRef}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...fowRest}
                />
              </FormControl>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <InputLabel shrink>Status Baptis</InputLabel>
                <Controller
                  name="is_baptised"
                  control={control}
                  defaultValue={user?.is_baptised || false}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      style={{ marginTop: 16 }}
                      control={
                        <Switch
                          color="primary"
                          onChange={({ target }) => onChange(target.checked)}
                          checked={value}
                        />
                      }
                      label="Sudah Baptis"
                    />
                  )}
                />
              </FormControl>
              {isBaptise ? (
                <Box display="flex" justifyContent="space-between">
                  <FormControl margin="normal">
                    <TextField
                      fullWidth
                      name="baptise_church"
                      label="Gereja tempat pembaptisan"
                      defaultValue={user.baptise_church}
                      inputRef={baptiseChurchRef}
                      {...baptiseChurchRest}
                    />
                  </FormControl>
                  <FormControl
                    style={{ minWidth: '48%', marginLeft: '16px' }}
                    margin="normal"
                  >
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <MobileDatePicker
                          sx={{
                            minWidth: '100%',
                          }}
                          views={['year']}
                          label="Tahun Baptis"
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(value) =>
                            onChange(format(value, '01/01/yyyy'))
                          }
                          value={value}
                        />
                      )}
                      name="baptise_year"
                      control={control}
                      defaultValue={new Date()}
                    />
                  </FormControl>
                </Box>
              ) : null}
              <Box display="flex" justifyContent="space-between">
                <FormControl style={{ minWidth: '100%' }} margin="normal">
                  <InputLabel>Status Perkawinan</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} label="Status Perkawinan">
                        <MenuItem value="SINGLE">Belum Menikah</MenuItem>
                        <MenuItem value="MARRIED">Menikah</MenuItem>
                        <MenuItem value="DIVORCED">Cerai</MenuItem>
                        <MenuItem value="WIDOWER">Duda</MenuItem>
                        <MenuItem value="WIDOWED">Janda</MenuItem>
                      </Select>
                    )}
                    name="marital_status"
                    control={control}
                    defaultValue={user.marital_status || 'SINGLE'}
                  />
                </FormControl>
              </Box>

              <Box>
                <FormControl style={{ minWidth: '100%' }} margin="normal">
                  <InputLabel>Status Keanggotaan</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} label="Status Keanggotaan">
                        <MenuItem value="PERMANENT">Tetap</MenuItem>
                        <MenuItem value="TEMPORARY">Simpatisan</MenuItem>
                      </Select>
                    )}
                    name="membership"
                    control={control}
                    defaultValue={user.membership || 'TEMPORARY'}
                  />
                </FormControl>

                {isTemp ? (
                  <FormControl style={{ minWidth: '100%' }} margin="normal">
                    <TextField
                      fullWidth
                      name="church_origin"
                      label="Gereja Asal"
                      helperText="Mohon diisi jika anda belum sebagai anggota tetap Gepembri"
                      defaultValue={user.church_origin}
                      inputRef={churchOrigRef}
                      {...churchOrigRest}
                    />
                  </FormControl>
                ) : null}
              </Box>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <MobileDatePicker
                      sx={{
                        minWidth: '100%',
                      }}
                      views={['year', 'month']}
                      label="Bergabung Sejak"
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(value) =>
                        onChange(format(value, 'MM/01/yyyy'))
                      }
                      value={value}
                    />
                  )}
                  name="join_date"
                  control={control}
                  defaultValue={new Date()}
                />
              </FormControl>
            </Paper>
            <Paper style={{ padding: 16 }}>
              <Typography variant="h6" component="h3">
                Info Donor
              </Typography>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <Controller
                  name="available_for_donor"
                  control={control}
                  defaultValue={user?.available_for_donor || false}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      style={{ marginTop: 16 }}
                      control={
                        <Switch
                          color="primary"
                          onChange={({ target }) => onChange(target.checked)}
                          checked={value}
                        />
                      }
                      label="Bersedia Dihubungi Jika Diperlukan"
                    />
                  )}
                />
              </FormControl>

              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <InputLabel>Golongan Darah</InputLabel>
                <Controller
                  render={({ field }) => (
                    <Select {...field} label="Golongan Darah">
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                      <MenuItem value="O">O</MenuItem>
                      <MenuItem value="AB">AB</MenuItem>
                      <MenuItem value="X">Saya Tidak/Belum Tahu</MenuItem>
                    </Select>
                  )}
                  name="blood_type"
                  control={control}
                  defaultValue={user.blood_type || 'X'}
                />
              </FormControl>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <InputLabel>Rhesus Darah</InputLabel>
                <Controller
                  render={({ field }) => (
                    <Select {...field} label="Rhesus Darah">
                      <MenuItem value="POSITIVE">Positif</MenuItem>
                      <MenuItem value="NEGATIVE">Negatif</MenuItem>
                      <MenuItem value="X">Saya Tidak/Belum Tahu</MenuItem>
                    </Select>
                  )}
                  name="blood_rhesus"
                  control={control}
                  defaultValue={user?.blood_rhesus || 'X'}
                />
              </FormControl>
            </Paper>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 3, marginBottom: 4 }}
              type="submit"
              startIcon={<SaveIcon />}
            >
              {loading ? 'Loading...' : 'Simpan'}
            </Button>
          </form>
        </Box>
      )}
    </React.Fragment>
  );
};

export default EditJemaat;
