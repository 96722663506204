import React from 'react';
import debounce from 'lodash.debounce';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Autocomplete } from '@mui/material';

import api from 'libs/utils/api';

const UserRelationDialog = ({ open, onClose, onSelect, user }) => {
  const [relation, setRelationship] = React.useState({
    userID: null,
    relationshipType: 'FATHER',
  });

  const [options, setOptions] = React.useState([]);

  const fetchData = debounce((name) => {
    api
      .get(`/users/search?name=${name}`)
      .then(({ data: res }) => {
        setOptions(res.data);
      })
      .catch(() => setOptions([]));
  }, 400);

  const handleSearchUser = ({ target }) => {
    if (target.value) {
      fetchData(target.value);
    } else {
      setOptions([]);
    }
  };

  const handleChangeRelationship = ({ target }) => {
    setRelationship({ ...relation, relationshipType: target.value });
  };

  return <>
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="select-relationship-dialog-title"
    >
      <DialogTitle id="select-relationship-dialog-title">
        Tambahkan Relasi
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Setelah menambahkan relasi, mohon mengkonfirmasi permintaan
          menambahkan relasi pada akun yang dituju.
        </DialogContentText>
        <FormControl fullWidth style={{ marginBottom: 16 }}>
          <Autocomplete
            onChange={(_, selected) =>
              setRelationship({ ...relation, userID: selected.id })
            }
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={options.filter((o) => o.id !== +user)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ketik nama jemaat..."
                fullWidth
                onChange={handleSearchUser}
                InputProps={params.InputProps}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Hubungan</InputLabel>
          <Select
            onChange={handleChangeRelationship}
            value={relation.relationshipType}
          >
            <MenuItem value="FATHER">Ayah</MenuItem>
            <MenuItem value="MOTHER">Ibu</MenuItem>
            <MenuItem value="SPOUSE">Suami-Istri</MenuItem>
            <MenuItem value="BROTHER">Saudara (Laki-Laki)</MenuItem>
            <MenuItem value="SISTER">Saudara (Perempuan)</MenuItem>
            <MenuItem value="SON">Anak (Laki-Laki)</MenuItem>
            <MenuItem value="DAUGHTER">Anak (Perempuan)</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ padding: '8px 16px' }}>
        <Button onClick={() => onClose()}>Batal</Button>
        <Button
          disabled={!relation.userID}
          onClick={() => {
            onSelect(relation);
          }}
          color="primary"
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default UserRelationDialog;
