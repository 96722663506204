import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
  ListItem,
  Button,
  ListSubheader,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { AddressCard, AddressDialog } from 'components';

const UserAddress = ({ onAssignAddress, userID, address }) => {
  const history = useHistory();
  const [addressDialogOpen, toggleAddressDialog] = React.useState(false);
  const handleCloseAddressDialog = () => {
    toggleAddressDialog(false);
  };

  const handleSelectAddressDialog = (selectedAddress) => {
    onAssignAddress(userID, selectedAddress);
  };

  return (
    <React.Fragment>
      <List subheader={<ListSubheader>Alamat</ListSubheader>} id="alamat">
        <ListItem>
          <Tooltip
            title="Mohon melengkapi alamat"
            open={!address}
            arrow
            placement="top"
          >
            <Button
              color="primary"
              fullWidth
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => toggleAddressDialog(true)}
            >
              {address ? 'Ganti Alamat' : 'Tambah Alamat'}
            </Button>
          </Tooltip>
        </ListItem>

        <ListItem>
          {address ? (
            <AddressCard
              hideDeleteButton
              id={address.id}
              name={address.name}
              address={address.address}
              province={address.province}
              regency={address.regency}
              district={address.district}
              villages={address.villages}
              latitude={address.latitude}
              longitude={address.longitude}
              handleEdit={() => history.push(`/d/address/${address.id}/edit`)}
            />
          ) : null}
        </ListItem>
      </List>
      <AddressDialog
        open={addressDialogOpen}
        onSelect={handleSelectAddressDialog}
        onClose={handleCloseAddressDialog}
      />
    </React.Fragment>
  );
};

export default UserAddress;
