import React from 'react';
import debounce from 'lodash.debounce';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Autocomplete } from '@mui/material';

import api from 'libs/utils/api';
import { useAuth } from 'libs/contexts/auth';

const FamilyDialog = ({ open, onClose, onSelect }) => {
  const { user } = useAuth();

  const [attendance, setAttendance] = React.useState({
    user_id: null,
    age: null,
    is_c19_vaccinated: false,
    attendance_role: 'JEMAAT',
  });

  const [options, setOptions] = React.useState([]);
  const [isAllowed, setAllowed] = React.useState(true);

  const handleAllowedByFamily = (e) => {
    setAllowed(e.target.checked);
  };

  const fetchData = debounce((name) => {
    api
      .get(`/users/?name=${name}&page=1&limit=100`)
      .then(({ data: res }) => {
        setOptions(res.data);
      })
      .catch(() => setOptions([]));
  }, 400);

  const handleSearchUser = ({ target }) => {
    if (target.value) {
      fetchData(target.value);
    } else {
      setOptions([]);
    }
  };

  const allowedAge = attendance.age >= 12;

  // isVaxxed only applied to Gepembri Kemurnian
  const isVaxxed =
    attendance.user_id && !attendance.is_c19_vaccinated && user.church_id === 1;

  const isStrictUser =
    attendance.age >= 60 && attendance.is_c19_vaccinated;

  return <>
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="select-attendanceship-dialog-title"
    >
      <DialogTitle id="select-attendanceship-dialog-title">
        Reservasi Ibadah
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selama PPKM hanya jemaat berusia 12-70 tahun yang boleh mengikuti
          ibadah.
          <div>
            <em>(khusus umur 61-70 tahun wajib vaksin)</em>
          </div>
        </DialogContentText>
        <FormControl fullWidth style={{ marginBottom: 16 }}>
          <Autocomplete
            onChange={(_, selected) => {
              if (selected) {
                setAttendance({
                  ...attendance,
                  user_id: selected.id,
                  age: selected.age,
                  is_c19_vaccinated: selected.is_c19_vaccinated,
                });

                if (selected.age > 60) {
                  setAllowed(false);
                }
              }
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ketik nama jemaat..."
                fullWidth
                onChange={handleSearchUser}
                InputProps={params.InputProps}
                error={(attendance.user_id && !allowedAge) || isVaxxed}
              />
            )}
          />
          {isVaxxed ? (
            <FormHelperText error>
              • Anda tercatat belum menerima vaksin COVID-19. Mohon tunjukkan
              sertifikat vaksin ke sekretaris gereja dan ulangi proses
              reservasi setelah dikonfirmasi oleh sekretaris gereja.
            </FormHelperText>
          ) : null}

          {attendance.user_id && !allowedAge ? (
            <FormHelperText error>
              • Batasan umur yang diijinkan hanya 12-70 tahun.
            </FormHelperText>
          ) : null}

          {isStrictUser ? (
            <>
              <FormControl>
                <FormControlLabel
                  style={{ marginTop: 16 }}
                  control={
                    <Checkbox
                      name="allowed_by_family"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color="primary"
                      onChange={handleAllowedByFamily}
                    />
                  }
                  label="Saya menyatakan telah diijinkan oleh keluarga untuk mengikuti ibadah."
                />
              </FormControl>
            </>
          ) : null}
        </FormControl>
      </DialogContent>
      <DialogActions style={{ padding: '8px 16px' }}>
        <Button onClick={() => onClose()}>Batal</Button>
        <Button
          disabled={isVaxxed || !allowedAge || !isAllowed}
          onClick={() => {
            const { age, ...rest } = attendance;
            onSelect(rest);
          }}
          color="primary"
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default FamilyDialog;
