export const genderMapper = {
  MALE: 'Laki-Laki',
  FEMALE: 'Perempuan',
};

export const maritalStatusMapper = {
  SINGLE: 'Belum Menikah',
  MARRIED: 'Menikah',
  DIVORCED: 'Cerai',
  WIDOWER: 'Duda',
  WIDOWED: 'Janda',
};

export const religionMapper = {
  KRISTEN: 'Kristen Protestan',
  KATHOLIK: 'Katholik',
  BUDDHA: 'Buddha',
  KONGHUCU: 'Konghucu',
  ISLAM: 'Islam',
  HINDU: 'Hindu',
};

export const membershipMapper = {
  PERMANENT: 'Jemaat Tetap',
  TEMPORARY: 'Simpatisan',
};

export const relationMapper = {
  FATHER: 'Ayah',
  MOTHER: 'Ibu',
  SPOUSE: 'Pasangan (Suami-Istri)',
  SON: 'Anak (Laki-Laki)',
  DAUGHTER: 'Anak (Perempuan)',
  BROTHER: 'Saudara (Laki-Laki)',
  SISTER: 'Saudara (Perempuan)',
};
