import React from 'react';
import { Box } from '@mui/material';

import { PrimaryNavigation, Loader, PollsCard, Empty } from 'components';
import { useVotingNS } from 'libs/hooks/voting';

function Polls() {
  const { data: namespaces, loading } = useVotingNS();

  return (
    <>
      <PrimaryNavigation title="Pemilihan" />
      <Box maxWidth="550px" marginX="auto">
        {loading && namespaces.length === 0 ? <Loader /> : null}
        {namespaces.length === 0 && !loading ? <Empty /> : null}
        <Box padding={2}>
          {namespaces.map((n) => (
            <PollsCard
              key={n.id}
              id={n.id}
              title={n.title}
              description={n.description}
              deadline={new Date(n.deadline)}
            />
          ))}
        </Box>
      </Box>
    </>
  );
}

export default Polls;
