import * as React from 'react';
import { useCookies } from 'react-cookie';
import { Route, Redirect } from 'react-router-dom';

import config from 'config';

const CustomRoute = ({ component, ...rest }) => {
  const [cookies] = useCookies([config.COOKIE_NAME]);
  if (!component) {
    throw Error('Component is undefined');
  }

  const Component = component;
  const render = (props) => {
    // @todo: check this token
    if (cookies.access_token) {
      return <Component {...props} />;
    }

    window.localStorage.setItem(config.LAST_VISIT, window.location.pathname);

    return <Redirect to={{ pathname: '/' }} />;
  };

  return <Route {...rest} render={render} />;
};

export default CustomRoute;
