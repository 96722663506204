import useSWR, { mutate } from 'swr';
import { useState } from 'react';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';

import api from 'libs/utils/api';
import useToast from 'libs/contexts/toasts';

const showNextDayAfterCreated = (w) => {
  const today = new Date();
  const createdAt = addDays(new Date(w.created_at), 1);

  return isAfter(today, startOfDay(createdAt));
};

const showOnlyOnSameday = (w) => {
  const today = new Date();
  const startedAt = new Date(w.started_at);

  return today.toLocaleDateString() === startedAt.toLocaleDateString();
};

export const useSchedules = () => {
  const pathKey = '/schedules/?is_coming=1';
  const { data = [], error, isValidating } = useSWR(pathKey);

  return {
    rawData: data,
    data: data.filter(showNextDayAfterCreated),
    loading: (!error && !data) || isValidating,
  };
};

export const useRegistered = () => {
  const pathKey = '/schedules/?is_coming=1&registered=1';
  const { data = [], error, isValidating } = useSWR(pathKey);

  return {
    rawData: data,
    data: data.filter(showNextDayAfterCreated),
    loading: (!error && !data) || isValidating,
  };
};

export const useInService = () => {
  const pathKey = '/schedules/?in_service=1';
  const { data = [], error, isValidating } = useSWR(pathKey);

  return {
    rawData: data,
    data: data.filter(showOnlyOnSameday),
    loading: (!error && !data) || isValidating,
  };
};

export const useSchedule = (scheduleID) => {
  const pathKey = `/schedules/${scheduleID}`;
  const { data = [], error, isValidating } = useSWR(pathKey);

  return {
    data,
    loading: (!error && !data) || isValidating,
  };
};

const defaultFilter = {
  servant: 0,
  congregation: 0,
  present: 0,
  not_present: 0,
};

export const useAbsence = (scheduleID, filter = defaultFilter) => {
  const qs = Object.keys(filter)
    .filter((k) => filter[k])
    .map((k) => `${k}=${filter[k]}`)
    .join('&');

  const pathKey = `/schedules/${scheduleID}/attendances?${
    qs ? qs + '&' : ''
  }page=1&limit=1000`;

  const {
    data = [],
    error,
    isValidating,
  } = useSWR(() => (scheduleID ? pathKey : null));

  return {
    data,
    loading: (!error && !data) || isValidating,
  };
};

export const useInvitationDetail = (scheduleID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onArrived = async (invitationID) => {
    setLoading(false);
    try {
      const { data: res } = await api.post(
        `/schedules/${scheduleID}/attendances/${invitationID}`,
      );

      if (res.success) {
        toast('success', 'Absen berhasil');
        return res.data;
      } else {
        toast('error', res.message);
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Terjadi kesalahan ketika melakukan reservasi.');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onArrived,
  };
};

export const useOnsiteAbsence = (scheduleID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onArrived = async (userSK) => {
    setLoading(false);
    try {
      const { data: res } = await api.post(
        `/schedules/${scheduleID}/onsite-attendances`,
        {
          user_sk: userSK,
        },
      );

      if (res.success) {
        return res.data;
      } else {
        toast('error', res.message);
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Absensi gagal, mohon ulangi sekali lagi.');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onArrived,
  };
};

export const useServants = (scheduleID) => {
  const pathKey = `/schedules/${scheduleID}/attendances?servant=1&limit=30`;
  const {
    data = [],
    error,
    isValidating,
  } = useSWR(() => (scheduleID ? pathKey : null));

  return {
    data,
    loading: (!error && !data) || isValidating,
  };
};

export const useFamilyInCharge = (scheduleID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const pathKey = `/schedules/${scheduleID}/attendances?family=1&page=1&limit=100`;
  const {
    data = [],
    error,
    isValidating,
  } = useSWR(() => (scheduleID ? pathKey : null));

  const onJoin = async (data) => {
    setLoading(true);
    try {
      const { data: res } = await api.post(
        `/schedules/${scheduleID}/attendances`,
        data,
      );

      if (res.success) {
        mutate(pathKey);
        toast('success', 'Data berhasil disimpan.');

        return res.data;
      } else {
        toast('error', 'Gagal menyimpan data.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal menyimpan data.');
    } finally {
      setLoading(false);
    }
  };

  const onCancel = async (invitationID) => {
    setLoading(true);
    try {
      const { data: res } = await api.delete(
        `/schedules/${scheduleID}/attendances`,
        {
          data: [invitationID],
        },
      );
      if (res.success) {
        mutate(pathKey);
      } else {
        toast('error', 'Gagal ketika membatalkan reservasi.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal ketika membatalkan reservasi.');
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading: (!error && !data) || isValidating || loading,
    onJoin,
    onCancel,
  };
};
