import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import ReactJson from 'react-json-view';

import { PrimaryNavigation, WorshipCard, Loader, Empty } from 'components';
import {
  useInService,
  useRegistered,
  useSchedules,
} from 'libs/hooks/schedules';
import useQuery from 'libs/utils/query';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={2}>{children}</Box>}
    </Box>
  );
};

const Debugger = (props) => (
  <ReactJson
    enableClipboard
    theme="monokai"
    displayDataTypes={false}
    collapsed={1}
    src={props.data}
  />
);

const ListWorship = () => {
  const query = useQuery();
  const isDebug = +query.get('debug') > 0;

  const {
    data: worships,
    loading: wLoading,
    rawData: rawWorship,
  } = useSchedules();
  const {
    data: registered,
    loading: rLoading,
    rawData: rawRegistered,
  } = useRegistered();
  const { data: services, loading: sLoading } = useInService();

  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <PrimaryNavigation title="Ibadah" />
      <Box maxWidth="550px" marginX="auto">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Semua" />
          {registered.some((x) => x.worship_type === 'RSVP') ? (
            <Tab label="Terdaftar" />
          ) : null}
          {services.length > 0 ? <Tab label="Tugas" /> : null}
        </Tabs>

        <TabPanel value={value} index={0}>
          {wLoading ? <Loader /> : null}
          {worships.length === 0 && !wLoading ? (
            <Empty text="Jadwal ibadah belum tersedia" />
          ) : null}
          {worships.map((worship) => (
            <WorshipCard
              key={worship.id}
              id={worship.id}
              title={worship.worship.description}
              date={worship.started_at}
              worshipType={worship.worship_type}
              description={worship.description}
              registered={worship.registered}
              capacity={worship.max_capacity}
            />
          ))}
          {isDebug ? (
            <Box>
              <span style={{ color: 'red', fontSize: 12 }}>
                Debugging: (worship schedule shows only day created + 1)
              </span>
              <Debugger data={worships} />

              <span style={{ color: 'red', fontSize: 12 }}>
                Debugging: (worship schedule no filter applied)
              </span>
              <Debugger data={rawWorship} />
            </Box>
          ) : null}
        </TabPanel>
        {registered.some((x) => x.worship_type === 'RSVP') ? (
          <TabPanel value={value} index={1}>
            {rLoading ? <Loader /> : null}
            {registered.length === 0 && !rLoading ? (
              <Empty text="Anda belum melakukan reservasi. Mohon reservasi terlebih dahulu" />
            ) : null}
            {registered.map((worship) => (
              <WorshipCard
                toReservation
                key={worship.id}
                id={worship.id}
                title={worship.worship.description}
                date={worship.started_at}
                worshipType={worship.worship_type}
                description={worship.description}
                registered={worship.registered}
                capacity={worship.max_capacity}
              />
            ))}
            {isDebug ? (
              <Box>
                <span style={{ color: 'red', fontSize: 12 }}>
                  Debugging: (reserved schedule shows only day created + 1)
                </span>
                <Debugger data={registered} />

                <span style={{ color: 'red', fontSize: 12 }}>
                  Debugging: (reserved schedule no filter applied)
                </span>
                <Debugger data={rawRegistered} />
              </Box>
            ) : null}
          </TabPanel>
        ) : null}
        {services.length > 0 ? (
          <TabPanel
            value={value}
            index={registered.some((x) => x.worship_type === 'RSVP') ? 2 : 1}
          >
            {sLoading ? <Loader /> : null}
            {services.map((worship) => (
              <WorshipCard
                toAbsentPage
                key={worship.id}
                id={worship.id}
                title={worship.worship.description}
                date={worship.started_at}
                worshipType={worship.worship_type}
                description={worship.description}
                registered={worship.registered}
                capacity={worship.max_capacity}
              />
            ))}
          </TabPanel>
        ) : null}
      </Box>
    </React.Fragment>
  );
};

export default ListWorship;
