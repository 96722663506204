import api from 'libs/utils/api';

const uploadImage = async (file, opts = { folder: 'others' }) => {
  if (!file) {
    throw Error('[uploadImage.js] File is required!');
  }

  const formData = new FormData();
  formData.set('picture', file);
  formData.set('folder', opts.folder);
  const { data: res } = await api.post('/uploads/', formData);
  return res.data;
};

export default uploadImage;
