export const worshipRoleMapper = {
  PENGKHOTBAH: 'Pengkhotbah',
  TRANSLATOR: 'Translator',
  HAMBA_TUHAN: 'Hamba Tuhan',
  LITURGIS: 'Liturgis',
  KOLEKTOR: 'Kolektor',
  PETUGAS_PERJAMUAN: 'Petugas Perjamuan',
  DOA_SYAFAAT: 'Doa Syafaat',
  DOA_SYUKUR: 'Doa Syukur',
  WARTA_JEMAAT: 'Warta Jemaat',
  DOA_BERKAT: 'Doa Berkat',
  SINGER: 'Singer',
  MUSIK: 'Musik',
  USHER: 'Usher',
  PETUGAS_REGISTRASI: 'Petugas Registrasi',
  PROTOKOLER_COVID: 'Protokoler COVID',
  MULTIMEDIA: 'Multimedia',
  JEMAAT: 'Jemaat',
  KEAMANAN: 'Keamanan',
  CHOIR: 'Paduan Suara',
  KOORDINATOR_IBADAH: 'Koordinator Ibadah',
  PELAYAN_MINGGU_DEPAN: 'Pelayan Minggu Depan',
};
