import React from 'react';
import {
  List,
  ListItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

const DeleteDialog = ({ open, onClose, onSelect, onClick }) => {
  return (
    <React.Fragment>
      <List>
        <ListItem>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onClick}
          >
            Hapus Data Jemaat Ini
          </Button>
        </ListItem>
      </List>
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="select-relationship-dialog-title"
      >
        <DialogTitle id="select-relationship-dialog-title">
          Hapus Data Anggota Keluarga
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sebelum menghapus data jemaat, harap{' '}
            <strong>menghapus semua relasi saudara</strong> dan{' '}
            <strong>keluar dari persekutuan</strong> terlebih dahulu. <br />
            <br /> Anda yakin akan menghapus data jemaat ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '8px 16px' }}>
          <Button onClick={() => onClose()}>Batal</Button>
          <Button
            onClick={() => {
              onSelect();
            }}
            color="primary"
          >
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DeleteDialog;
