import useSWR, { mutate } from 'swr';
import { useState } from 'react';

import api from 'libs/utils/api';
import useToast from 'libs/contexts/toasts';

export const useUserRelation = (userID) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const pathKey = `/relationships/${userID}`;
  const {
    data = [],
    error,
    isValidating,
  } = useSWR(() => (userID ? pathKey : null));

  const onAdd = async ({ relationshipType, relativeID }) => {
    setLoading(true);
    try {
      const { data: res } = await api.post(`/relationships/${userID}`, {
        relative_id: relativeID,
        relationship_type: relationshipType,
      });
      if (res.success) {
        mutate(pathKey);
        toast('success', 'Data berhasil disimpan.');
      } else {
        toast('error', 'Gagal menyimpan data.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal menyimpan data.');
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = async (relativeID) => {
    setLoading(true);
    try {
      const { data: res } = await api.patch(`/relationships/${userID}`, {
        relative_id: relativeID,
      });
      if (res.success) {
        mutate(pathKey);
        toast('success', 'Relasi berhasil dikonfirmasi.');
      } else {
        toast('error', 'Gagal mengkonfirmasi relasi.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal mengkonfirmasi relasi.');
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (relativeID) => {
    setLoading(true);
    try {
      const { data: res } = await api.delete(`/relationships/${userID}`, {
        data: {
          relative_id: relativeID,
        },
      });
      if (res.success) {
        mutate(pathKey);
        toast('success', 'Data berhasil dihapus.');
      } else {
        toast('error', 'Gagal menghapus data.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal menghapus data.');
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading: (!error && !data) || isValidating || loading,
    onAdd,
    onConfirm,
    onDelete,
  };
};
