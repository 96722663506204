import jwt from 'jsonwebtoken';
import get from 'lodash.get';

export const getRole = token => {
  const decoded = jwt.decode(token);
  return get(decoded, 'user_claims.role', null);
};

export const getChurch = token => {
  const decoded = jwt.decode(token);
  return get(decoded, 'user_claims.church_id', null);
};
