import React from 'react';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Group from '@mui/icons-material/Group';
import LocationOn from '@mui/icons-material/LocationOn';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PollIcon from '@mui/icons-material/Poll';

import { useAuth } from 'libs/contexts/auth';

const drawerMenu = (history, namespaces) => {
  const onlyOneNamespace = namespaces.length === 1;

  return [
    {
      name: 'Ibadah',
      icon: <HomeWorkIcon />,
      onClick: () => history.push('/d'),
    },
    {
      name: 'Keluarga',
      icon: <Group />,
      onClick: () => history.push('/d/jemaat'),
    },
    {
      name: 'Alamat',
      icon: <LocationOn />,
      onClick: () => history.push('/d/address'),
    },

    // If only 1 voting available
    // Go directly to the vote detail page
    {
      name: 'Voting',
      icon: <PollIcon />,
      onClick: () =>
        history.push(
          onlyOneNamespace ? `/d/polls/${namespaces[0].id}` : '/d/polls',
        ),
    },
  ];
};

const PrimaryMenu = ({ title, hideMenu }) => {
  const history = useHistory();
  const [isOpen, toggleDrawer] = React.useState(false);
  const { isAuthenticated, logout } = useAuth();
  const { data: namespaces = [] } = useSWR(() =>
    isAuthenticated ? '/poll-namespaces/' : null,
  );

  return (
    <React.Fragment>
      <Box marginX="auto" marginTop={7} maxWidth="550px">
        <AppBar position="fixed">
          <Toolbar style={{ width: '100%', maxWidth: 550, margin: '0 auto' }}>
            {hideMenu ? null : (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => toggleDrawer(true)}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6">{title}</Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer open={isOpen} onClose={() => toggleDrawer(false)}>
        <Box
          width="300px"
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <List>
            <ListItem>
              <ListItemText
                primary={<Typography variant="h6">Gepembri</Typography>}
              />
            </ListItem>
          </List>
          <List>
            {drawerMenu(history, namespaces).map((menu) => (
              <ListItem key={menu.name} button onClick={menu.onClick}>
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItem>
            ))}
            <Divider />

            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Keluar" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

PrimaryMenu.defaultProps = {
  hideMenu: false,
};

export default PrimaryMenu;
