import React from 'react';
import {
  Box,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Card,
} from '@mui/material';
import format from 'date-fns/format';

import { PrimaryNavigation, Loader, Empty, PollsCard } from 'components';
import { getImage } from 'libs/utils/image';
import { useVoters, useVotingNSDetail } from 'libs/hooks/voting';

function ListVoters({ history, match }) {
  const { data: voters, vLoading } = useVoters(match.params.id);
  const { data: namespace, nsLoading } = useVotingNSDetail(match.params.id);

  return (
    <React.Fragment>
      <PrimaryNavigation title="Surat Suara" />
      <Box maxWidth="550px" marginX="auto">
        {nsLoading && namespace.length === 0 ? <Loader /> : null}
        {!namespace && !nsLoading ? <Empty /> : null}
        {namespace ? (
          <Box padding={2}>
            <PollsCard
              title={namespace.title}
              description={namespace.description}
              deadline={new Date(namespace.deadline)}
            />
          </Box>
        ) : null}
        <Box paddingX={2} paddingBottom={2}>
          <Card>
            <Box paddingX={2} paddingTop={2}>
              <Typography variant="body2" style={{ fontWeight: 700 }}>
                DAFTAR PEMILIH
              </Typography>
              <Divider style={{ marginTop: 8 }} />
            </Box>
            {vLoading && voters.length === 0 ? <Loader /> : null}
            {voters.length === 0 && !vLoading ? <Empty /> : null}
            <List>
              {voters.map((voter, idx, arr) => {
                return (
                  <React.Fragment key={idx}>
                    <ListItem
                      button
                      disabled={voter.voted_at}
                      onClick={() => {
                        history.push(
                          `/d/polls/${match.params.id}/vote/${voter.id}`,
                        );
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={voter.user.name}
                          src={getImage(voter.user.picture, 100)}
                        />
                      </ListItemAvatar>
                      <ListItemText>
                        <Typography variant="body1">
                          {voter.user.name}
                        </Typography>
                        <Typography
                          component="span"
                          variant="caption"
                          color="textSecondary"
                        >
                          {voter.voted_at
                            ? 'Sudah memilih. (' +
                              format(
                                new Date(voter.voted_at),
                                'dd MMM yyyy, HH:mm',
                              ) +
                              ')'
                            : null}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    {idx === arr.length - 1 ? null : (
                      <Divider variant="inset" component="li" />
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          </Card>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default ListVoters;
