import React from 'react';
import * as QRCode from 'easyqrcodejs';

class QRCodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.qrcode = React.createRef();
  }

  componentDidMount() {
    const { value, name, worshipDate } = this.props;
    var options = {
      text: value,
      width: 180,
      height: 180,
      title: name,
      subTitle: worshipDate,
      subTitleTop: 30,
      titleTop: 15,
      titleHeight: 40,
      quietZone: 50,
      PI: '#3f51b5',
      quietZoneColor: '#FFFFFF',
    };

    new QRCode(this.qrcode.current, options);
  }

  render() {
    return (
      <React.Fragment>
        <div id="qrcode" ref={this.qrcode} />
      </React.Fragment>
    );
  }
}

export class QRCodeUser extends React.Component {
  constructor(props) {
    super(props);
    this.qrcode = React.createRef();
  }

  componentDidMount() {
    const { value, name } = this.props;
    var options = {
      text: value,
      width: 180,
      height: 180,
      title: name,
      subTitle: 'v1.0.1',
      subTitleTop: 30,
      titleTop: 15,
      titleHeight: 40,
      quietZone: 50,
      PI: '#3f51b5',
      quietZoneColor: '#FFFFFF',
    };

    new QRCode(this.qrcode.current, options);
  }

  render() {
    return (
      <React.Fragment>
        <div id="qrcode" ref={this.qrcode} />
      </React.Fragment>
    );
  }
}

export class QRCodeCard extends React.Component {
  constructor(props) {
    super(props);
    this.qrcode = React.createRef();
  }

  componentDidMount() {
    const { value } = this.props;
    var options = {
      text: value,
      width: 180,
      height: 180,
      quietZone: 10,
      PI: '#3f51b5',
      quietZoneColor: '#FFFFFF',
    };

    new QRCode(this.qrcode.current, options);
  }

  render() {
    return (
      <React.Fragment>
        <div id="qrcode" ref={this.qrcode} />
      </React.Fragment>
    );
  }
}

export default QRCodeComponent;
