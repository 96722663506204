import useSWR, { mutate } from 'swr';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useToast from 'libs/contexts/toasts';
import api from 'libs/utils/api';

export const useAddress = () => {
  const toast = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { data = [], error, isValidating } = useSWR('/addresses/');

  const onAdd = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const { data: res } = await api.post('/addresses/', data);
        if (res.success) {
          mutate('/addresses/');
          toast('success', 'Alamat berhasil disimpan.');

          history.goBack();
        } else {
          toast('error', 'Terjadi kesalahan ketika menyimpan alamat');
        }
      } catch (error) {
        toast('error', error);
      } finally {
        setLoading(false);
      }
    },
    [history, toast],
  );

  const onDelete = useCallback(
    async (addressID) => {
      try {
        setLoading(true);
        const { data: res } = await api.delete('/addresses/', {
          data: [addressID],
        });

        if (res.success) {
          mutate('/addresses/');
          toast('success', 'Alamat berhasil dihapus.');
        } else {
          toast('error', 'Terjadi kesalahan ketika menghapus alamat');
        }
      } catch (error) {
        toast('error', error);
      } finally {
        setLoading(false);
      }
    },
    [toast],
  );

  return {
    data,
    loading: (!error && !data) || isValidating || loading,
    onAdd,
    onDelete,
  };
};

export const useAddressDetail = ({ addressID }) => {
  const toast = useToast();
  const history = useHistory();
  const { data, error, isValidating } = useSWR(`/addresses/${addressID}`);

  const onEdit = useCallback(
    async (data) => {
      try {
        const { data: res } = await api.put(`/addresses/${addressID}`, data);
        if (res.success) {
          mutate(`/addresses/${addressID}`);
          toast('success', 'Alamat berhasil disimpan.');

          history.goBack();
        } else {
          toast('error', 'Terjadi kesalahan ketika menyimpan alamat');
        }
      } catch (error) {
        toast('error', error);
      }
    },
    [history, addressID, toast],
  );

  return {
    data,
    loading: (!error && !data) || isValidating,
    onEdit,
  };
};
