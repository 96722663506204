import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Badge,
  Divider,
  Stack,
  Box,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';

const WorshipCard = (props) => {
  const history = useHistory();
  const worshipDate = new Date(props.date);
  const today = new Date();

  return (
    <Card style={{ marginBottom: 16 }}>
      <CardMedia
        component="img"
        height="120"
        image="/pattern.png"
        alt="Default pattern"
      />
      <CardContent>
        <Badge
          badgeContent={
            today.getDate() === worshipDate.getDate() ? 'HARI INI' : null
          }
          color="secondary"
          style={{ width: '93%' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
        </Badge>
        <Typography variant="body2" component="p" style={{ marginBottom: 8 }}>
          {props.description}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          justifyContent={
            props.worshipType === 'RSVP' ? 'space-between' : 'flex-start'
          }
          fontSize="13px"
          color="#696969"
        >
          <Box display="flex" alignItems="center">
            <CalendarMonthIcon sx={{ marginRight: 1 }} />{' '}
            {format(worshipDate, 'dd MMM yyyy')}
          </Box>
          <Box display="flex" alignItems="center">
            <ScheduleIcon sx={{ marginRight: 1 }} />{' '}
            {format(worshipDate, 'HH:mm')}
          </Box>
          {props.worshipType === 'RSVP' ? (
            <Box display="flex" alignItems="center">
              <ReduceCapacityIcon sx={{ marginRight: 1 }} /> {props.registered}/
              {props.capacity} orang
            </Box>
          ) : null}
        </Stack>
      </CardContent>
      <Divider />
      <CardActions>
        {props.toAbsentPage ? (
          <Button
            size="small"
            color="primary"
            fullWidth
            variant="contained"
            onClick={() => {
              const absentPageURL =
                props.worshipType === 'RSVP'
                  ? `/d/worships/${props.id}/absent`
                  : `/d/worships/${props.id}/onsite-absent`;
              history.push(absentPageURL);
            }}
          >
            Mulai Absensi
          </Button>
        ) : (
          <React.Fragment>
            {!props.toReservation ? (
              <Button
                size="small"
                color="primary"
                fullWidth
                variant="outlined"
                onClick={() => {
                  history.push(`/d/worships/${props.id}`);
                }}
              >
                Detail
              </Button>
            ) : null}
            {props.worshipType === 'RSVP' ? (
              <Button
                size="small"
                color="primary"
                fullWidth
                variant="contained"
                disabled={
                  (today > worshipDate && !props.toReservation) ||
                  (props.registered >= props.capacity && !props.toReservation)
                }
                onClick={() => {
                  const targetURL = `/d/worships/${props.id}?tab=reservation${
                    props.toReservation ? '' : '&modal=1'
                  }`;
                  history.push(targetURL);
                }}
              >
                {props.toReservation
                  ? 'Semua QR Code'
                  : props.registered >= props.capacity && !props.toReservation
                  ? 'Quota Habis'
                  : 'Reservasi'}
              </Button>
            ) : null}
            {props.worshipType === 'ONSITE' ? (
              <Button
                size="small"
                color="primary"
                fullWidth
                variant="contained"
                onClick={() => {
                  history.push('/d/jemaat');
                }}
              >
                Semua QR Code
              </Button>
            ) : null}
          </React.Fragment>
        )}
      </CardActions>
    </Card>
  );
};

export default WorshipCard;
