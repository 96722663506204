import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from '@mui/material';
import lodashGet from 'lodash.get';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

import { getImage } from 'libs/utils/image';

const VotingCard = ({ poll, selected, onChange }) => {
  const titleZh = lodashGet(poll, 'title_zh', '');

  return (
    <Card style={{ marginBottom: 16 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {poll.title} {titleZh !== '' ? `(${titleZh})` : ''}
        </Typography>
        <Typography gutterBottom variant="body2" color="textSecondary">
          {poll.description || null}
          {poll.description_zh ? (
            <>
              <br /> {poll.description_zh}
            </>
          ) : null}
        </Typography>
        <List>
          {poll.options.map((option, idx, arr) => {
            return (
              <React.Fragment key={idx}>
                <ListItem
                  button
                  onClick={() => onChange({ poll: poll.id, option: option.id })}
                >
                  <ListItemAvatar style={{ marginRight: 16 }}>
                    {selected.find((s) => s.option === option.id) ? (
                      <Avatar
                        style={{ background: 'green', width: 80, height: 80 }}
                      >
                        <CheckIcon />
                      </Avatar>
                    ) : (
                      <Avatar
                        alt={option.name}
                        src={getImage(option.picture, 100)}
                        style={{ width: 80, height: 80 }}
                      />
                    )}
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography variant="body1">{option.name}</Typography>
                    {option.name_zh ? (
                      <Typography variant="body1">
                        ({option.name_zh})
                      </Typography>
                    ) : null}
                    {option?.description?.split('\n').map((text, idx) => (
                      <Typography
                        key={idx}
                        variant="caption"
                        color="textSecondary"
                      >
                        {text}
                        <br />
                      </Typography>
                    ))}
                  </ListItemText>
                </ListItem>
                {idx === arr.length - 1 ? null : (
                  <Divider variant="inset" component="li" />
                )}
              </React.Fragment>
            );
          })}
        </List>
        <Box>
          <Alert
            severity={
              selected.filter((s) => s.poll === poll.id).length !==
              poll.min_selection
                ? 'error'
                : 'success'
            }
          >
            Terpilih (已選): {selected.filter((s) => s.poll === poll.id).length}
            /{poll.min_selection}
          </Alert>
        </Box>
      </CardContent>
    </Card>
  );
};

export default VotingCard;
