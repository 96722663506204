import _get from 'lodash.get';
import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Avatar,
  Divider,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import groupBy from 'lodash.groupby';

import { SecondaryNavigation, Loader, Empty, VotingCard } from 'components';
import { getImage } from 'libs/utils/image';
import { usePolls, useVoter, useVotingNSDetail } from 'libs/hooks/voting';
import uploadImage from 'libs/utils/uploadImage';
import useToast from 'libs/contexts/toasts';

function getVolunteerId(voter) {
  const volunteerId = _get(voter, 'volunteer.id', 0);
  return volunteerId;
}

function isRequireAnEvidence(voter) {
  const volunteerId = getVolunteerId(voter);
  return volunteerId > 0;
}

const Votings = ({ match }) => {
  const id = match.params.id;
  const voterId = match.params.voter_id;

  const toast = useToast();
  const { data: namespace } = useVotingNSDetail(id);
  const {
    data: currentVoter,
    loading: cvLoading,
    onVote,
  } = useVoter(id, voterId);
  const { data: polls } = usePolls(id);

  const [selected, setSelected] = React.useState(() => {
    const fromStorage = localStorage.getItem(`selected_${voterId}`) || '[]';
    return JSON.parse(voterId ? fromStorage : '[]');
  });
  const [isUploading, setUploadStatus] = React.useState(false);
  const [preview, setPreview] = React.useState('');
  const [confirmationDialog, setConfirmationDialog] = React.useState(false);

  React.useEffect(() => {
    if (selected && voterId) localStorage.setItem(`selected_${voterId}`, JSON.stringify(selected));
  }, [selected, voterId]);

  const initPollsSelection = polls.reduce((a, b) => {
    a[b.id] = 0;
    return a;
  }, {});

  const selectedTotal = selected.reduce((a, b) => {
    if (a[b.poll]) {
      a[b.poll] += 1;
    } else {
      a[b.poll] = 1;
    }

    return a;
  }, initPollsSelection);

  return (
    <React.Fragment>
      <SecondaryNavigation title="Pemilihan" />
      <Box maxWidth="550px" marginX="auto">
        {cvLoading && !currentVoter ? <Loader /> : null}
        {!currentVoter && !cvLoading ? <Empty /> : null}
        {currentVoter ? (
          <React.Fragment>
            <form>
              <Box padding={2}>
                <Alert style={{ marginBottom: 16 }} severity="info">
                  <Typography style={{ fontSize: 14 }}>
                    Silakan klik/tekan pada gambar atau nama pada pilihan yang
                    tersedia untuk memilih.
                  </Typography>{' '}
                  <Typography style={{ fontSize: 14 }}>
                    Tekan lagi untuk membatalkan pilihan.
                  </Typography>
                  <Typography style={{ marginTop: 8, fontSize: 14 }}>
                    請在候选人照片或姓名上点按您所選舉的執事。
                  </Typography>{' '}
                  <Typography style={{ fontSize: 14 }}>
                    若您再按一次，則取消先前所選舉的。
                  </Typography>
                </Alert>

                {currentVoter && currentVoter?.user ? (
                  <Card style={{ marginBottom: 16 }}>
                    <Box paddingX={2} paddingTop={2}>
                      <Typography variant="body2" style={{ fontWeight: 700 }}>
                        PEMILIH
                      </Typography>
                      <Divider style={{ marginTop: 8 }} />
                    </Box>
                    <Box padding={2}>
                      <Box display="flex" marginTop={1}>
                        <Box marginRight={1}>
                          <Avatar src={currentVoter.user.picture} />
                        </Box>
                        <Box>
                          <Typography variant="body2">
                            {currentVoter.user.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                ) : null}
                {polls.map((poll) => {
                  return (
                    <VotingCard
                      key={poll.id}
                      poll={poll}
                      selected={selected}
                      onChange={(opt) => {
                        if (selected.find((s) => s.option === opt.option)) {
                          setSelected(
                            selected.filter((x) => x.option !== opt.option),
                          );
                        } else {
                          const totalVote = selected.filter(
                            (s) => s.poll === poll.id,
                          ).length;
                          if (totalVote < poll.max_selection) {
                            setSelected([...selected, opt]);
                          } else {
                            toast(
                              'error',
                              'Pilihan sudah mencapai batas maksimal. Tekan lagi pada foto atau nama untuk membatalkan pilihan',
                            );
                          }
                        }
                      }}
                    />
                  );
                })}

                {isRequireAnEvidence(currentVoter) && (
                  <Card style={{ marginBottom: 16 }}>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="p">
                        Bukti Kuasa
                      </Typography>
                      <label htmlFor="input-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          disabled={isUploading}
                        >
                          {isUploading ? 'Sedang mengunggah...' : 'Upload Foto'}
                        </Button>
                      </label>
                      <input
                        accept="image/*"
                        id="input-file"
                        type="file"
                        style={{ visibility: 'hidden' }}
                        onChange={async (e) => {
                          if (e.currentTarget.files.length > 0) {
                            setUploadStatus(true);
                            const [file] = e.currentTarget.files;
                            try {
                              const res = await uploadImage(file, {
                                folder: 'evidence',
                              });
                              setPreview(res.secure_url);
                            } catch (e) {
                              toast('error', 'Gagal mengunggah foto!');
                            } finally {
                              setUploadStatus(false);
                            }
                          }
                        }}
                        onClick={(e) => (e.target.value = null)}
                      />
                      {preview && (
                        <Avatar
                          variant="square"
                          style={{ width: '100%', height: 'auto' }}
                          alt="Uploads"
                          src={getImage(preview)}
                        />
                      )}
                    </CardContent>
                  </Card>
                )}

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setConfirmationDialog(true)}
                  disabled={namespace && !namespace.is_available_to_vote}
                >
                  {namespace && !namespace.is_available_to_vote
                    ? 'Pemilihan Belum Dimulai'
                    : 'Selesai Memilih'}
                </Button>
              </Box>
              <Dialog
                open={confirmationDialog}
                onClose={() => setConfirmationDialog(false)}
                style={{
                  minWidth: '100%',
                  maxWidth: 550,
                }}
              >
                <DialogTitle>Simpan Pilihan</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Typography>
                      Mohon pastikan jumlah pilihan anda sudah benar
                    </Typography>
                    <Typography>請您确認您所選舉的執事。</Typography>
                    {polls.map((poll) => {
                      return (
                        <Box key={poll.id}>
                          <Typography>{poll.name}</Typography>
                          <Alert
                            severity={
                              selected.filter((s) => s.poll === poll.id)
                                .length !== poll.min_selection
                                ? 'error'
                                : 'success'
                            }
                          >
                            Terpilih (已選):{' '}
                            {selected.filter((s) => s.poll === poll.id).length}/
                            {poll.min_selection}
                          </Alert>
                        </Box>
                      );
                    })}
                    {currentVoter && currentVoter.volunteer ? (
                      <Box style={{ marginTop: 16 }}>
                        <Alert severity={preview === '' ? 'error' : 'success'}>
                          Bukti Kuasa
                        </Alert>
                      </Box>
                    ) : null}
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: 24 }}>
                  <Button
                    onClick={() => setConfirmationDialog(false)}
                    color="secondary"
                    variant="contained"
                  >
                    Batal
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();

                      const initPollsData = polls.map((p) => ({
                        poll_id: p.id,
                        votings: [],
                      }));
                      const votings = groupBy(selected, 'poll');
                      const votes = initPollsData.map((p) => {
                        return {
                          poll_id: p.poll_id,
                          votings: votings[p.poll_id]
                            ? votings[p.poll_id].map((o) => o.option)
                            : [],
                        };
                      });

                      const payload = {
                        votes,
                        voter_id: currentVoter.id,
                        evidence: preview,
                      };

                      onVote(payload);
                    }}
                    color="primary"
                    variant="contained"
                    disabled={
                      currentVoter && currentVoter.volunteer
                        ? !(
                            preview !== '' &&
                            polls.every(
                              (p) => selectedTotal[p.id] === p.min_selection,
                            )
                          )
                        : !polls.every(
                            (p) => selectedTotal[p.id] === p.min_selection,
                          )
                    }
                    autoFocus
                  >
                    Submit Pilihan
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </React.Fragment>
        ) : (
          <Empty />
        )}
      </Box>
    </React.Fragment>
  );
};

export default Votings;
