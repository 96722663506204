import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Box,
  Button,
  TextField,
  Divider,
  Typography,
  FormHelperText,
  FormControl,
} from '@mui/material';

import { SecondaryNavigation, DragableMaps } from 'components';
import { useAutocompleteLocation } from 'libs/hooks/location';
import { useAddress } from 'libs/hooks/address';
import countries from 'pages/Address/coutries.json';

const TambahAlamatJemaat = () => {
  const { handleSubmit, register, errors, setValue } = useForm();
  const { onAdd, loading } = useAddress();
  const [options, setQuery, locationLoading] = useAutocompleteLocation();
  const [selectedLoc, setSelectedLoc] = useState({
    country: 'Indonesia',
    province: '',
    regency: '',
    district: '',
    villages: '',
  });

  const onSubmit = (values) => {
    onAdd(values);
  };

  const handleDragEnd = (coords) => {
    setValue('latitude', coords.lat);
    setValue('longitude', coords.lng);
  };

  const { ref: nameRef, ...nameRest } = register('name', { required: true });
  const { ref: addressRef, ...addressRest } = register('address', {
    required: true,
  });
  const { ref: provinceRef, ...provinceRest } = register('province', {
    required: true,
  });
  const { ref: regencyRef, ...regencyRest } = register('regency', {
    required: true,
  });
  const { ref: districtRef, ...districtRest } = register('district', {
    required: true,
  });
  const { ref: villagesRef, ...villagesRest } = register('villages', {
    required: true,
  });

  return (
    <React.Fragment>
      <SecondaryNavigation title="Tambah Alamat" />
      <Box marginX="auto" padding={2} maxWidth="550px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            fullWidth
            name="name"
            label="Alamat disimpan sebagai:"
            margin="normal"
            error={errors?.name}
            InputLabelProps={{
              shrink: true,
            }}
            {...nameRest}
            inputRef={nameRef}
          />
          <TextField
            fullWidth
            name="address"
            label="Alamat"
            margin="normal"
            error={errors?.address}
            InputLabelProps={{
              shrink: true,
            }}
            {...addressRest}
            inputRef={addressRef}
          />
          <Box marginBottom={1}>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <Autocomplete
                options={countries}
                name="country"
                autoComplete
                defaultValue={{ name: 'Indonesia', code: 'ID' }}
                getOptionLabel={(option) => option.name}
                includeInputInList
                onChange={(_, value) => {
                  if (value) {
                    setSelectedLoc({ ...selectedLoc, country: value.name });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Negara"
                    fullWidth
                  />
                )}
              />
              <input
                type="hidden"
                name="country"
                defaultValue={selectedLoc.country}
                value={selectedLoc.country}
                {...register('country', { required: true })}
              />
            </FormControl>
          </Box>
          {selectedLoc.country === 'Indonesia' ? (
            <React.Fragment>
              <Autocomplete
                getOptionLabel={(option) => option.location}
                options={options}
                autoComplete
                includeInputInList
                loading={locationLoading}
                noOptionsText="Ketik nama kelurahan"
                error={errors?.address}
                onChange={(_, value) => {
                  if (value) {
                    const getName = (field) =>
                      value.detail.find((d) => d.type === field).name || null;

                    setSelectedLoc({
                      ...selectedLoc,
                      province: getName('provinces'),
                      regency: getName('regencies'),
                      district: getName('districts'),
                      villages: getName('villages'),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Kecamatan/Kelurahan"
                    fullWidth
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                )}
              />

              <input
                type="hidden"
                name="province"
                value={selectedLoc.province}
                {...register('province', { required: true })}
              />
              <input
                type="hidden"
                name="regency"
                value={selectedLoc.regency}
                {...register('regency', { required: true })}
              />
              <input
                type="hidden"
                name="district"
                value={selectedLoc.district}
                {...register('district', { required: true })}
              />
              <input
                type="hidden"
                name="villages"
                value={selectedLoc.villages}
                {...register('villages', { required: true })}
              />
            </React.Fragment>
          ) : null}
          {selectedLoc.country !== 'Indonesia' ? (
            <React.Fragment>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  name="province"
                  margin="normal"
                  label="Provinsi"
                  style={{ minWidth: '48%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.province}
                  onChange={(e) =>
                    setSelectedLoc({ ...selectedLoc, province: e.target.value })
                  }
                  {...provinceRest}
                  inputRef={provinceRef}
                />
                <TextField
                  name="regency"
                  margin="normal"
                  label="Kabupaten/Kota"
                  style={{ minWidth: '48%', marginLeft: 16 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.regency}
                  onChange={(e) =>
                    setSelectedLoc({ ...selectedLoc, regency: e.target.value })
                  }
                  {...regencyRest}
                  inputRef={regencyRef}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  name="district"
                  margin="normal"
                  label="Kecamatan"
                  style={{ minWidth: '48%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.district}
                  onChange={(e) =>
                    setSelectedLoc({ ...selectedLoc, district: e.target.value })
                  }
                  {...districtRest}
                  inputRef={districtRef}
                />
                <TextField
                  name="villages"
                  margin="normal"
                  label="Kelurahan/Desa"
                  style={{ minWidth: '48%', marginLeft: 16 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.villages}
                  onChange={(e) =>
                    setSelectedLoc({ ...selectedLoc, villages: e.target.value })
                  }
                  {...villagesRest}
                  inputRef={villagesRef}
                />
              </Box>
            </React.Fragment>
          ) : null}
          <Typography
            gutterBottom
            variant="h6"
            component="h3"
            style={{ marginTop: 16, marginBottom: 0 }}
          >
            Pilih di peta:
          </Typography>
          <FormHelperText style={{ marginBottom: 16 }}>
            Geser gunakan dua jari untuk menyesuaikan posisi alamat anda di
            peta.
          </FormHelperText>

          {errors?.longitude ? (
            <FormHelperText>Mohon pilih lokasi anda di peta</FormHelperText>
          ) : null}

          <DragableMaps onDragEnd={handleDragEnd} />
          <Divider style={{ margin: '16px 0' }} />
          <input
            type="hidden"
            name="latitude"
            {...register('latitude', { required: true })}
          />
          <input
            type="hidden"
            name="longitude"
            {...register('longitude', { required: true })}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: 16 }}
            type="submit"
          >
            {loading ? 'Loading...' : 'Simpan'}
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
};

export default TambahAlamatJemaat;
