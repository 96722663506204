import useSWR from 'swr';
import { useState } from 'react';

import api from 'libs/utils/api';
import { useAuth } from 'libs/contexts/auth';
import useToast from 'libs/contexts/toasts';
import { useHistory } from 'react-router-dom';

export const useVotingNS = () => {
  const { isAuthenticated } = useAuth();
  const pathKey = '/poll-namespaces/';
  const {
    data = [],
    error,
    isValidating,
  } = useSWR(() => (isAuthenticated ? pathKey : null));

  return {
    data,
    loading: (!error && !data) || isValidating,
  };
};

export const useVotingNSDetail = (namespaceID) => {
  const pathKey = `/poll-namespaces/${namespaceID}`;
  const {
    data,
    error,
    isValidating,
  } = useSWR(() => (namespaceID ? pathKey : null));

  return {
    data,
    loading: (!error && !data) || isValidating,
  };
};

export const useVoters = (namespaceID) => {
  const pathKey = `/poll-namespaces/${namespaceID}/voters`;
  const {
    data = [],
    error,
    isValidating,
  } = useSWR(() => (namespaceID ? pathKey : null));

  return {
    data,
    loading: (!error && !data) || isValidating,
  };
};

export const useVoter = (namespaceID, voterID) => {
  const toast = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const pathKey = `/poll-namespaces/${namespaceID}/voters/${voterID}`;
  const {
    data,
    error,
    isValidating,
  } = useSWR(() => (namespaceID && voterID ? pathKey : null));

  const onVote = async (payload) => {
    setLoading(true);
    try {
      const { data: res } = await api.post(
        `/poll-namespaces/${namespaceID}/votes`,
        payload,
      );

      if (res.data) {
        toast('success', 'Pilihan sudah berhasil disimpan.');
        history.goBack();
      } else {
        toast('error', 'Terjadi kesalahan: ' + res.message);
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Terjadi kesalahan pada sistem.');
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading: (!error && !data) || isValidating || loading,
    onVote,
  };
};

export const usePolls = (namespaceID) => {
  const pathKey = `/poll-namespaces/${namespaceID}/polls`;
  const {
    data = [],
    error,
    isValidating,
  } = useSWR(() => (namespaceID ? pathKey : null));

  return {
    data,
    loading: (!error && !data) || isValidating,
  };
};
