import React from 'react';
import { paramCase } from 'change-case';
import {
  Card,
  Divider,
  CardContent,
  Typography,
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Tabs,
  Tab,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  ListItemSecondaryAction,
  IconButton,
  DialogActions,
} from '@mui/material';
import { Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import format from 'date-fns/format';

import {
  SecondaryNavigation,
  Loader,
  FamilyDialog,
  QRCode,
  Empty,
} from 'components';
import { getImage } from 'libs/utils/image';
import { worshipRoleMapper } from 'pages/Worship/utils';
import {
  useFamilyInCharge,
  useSchedule,
  useServants,
} from 'libs/hooks/schedules';
import useQuery from 'libs/utils/query';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={2}>{children}</Box>}
    </Box>
  );
};

const WorshipDetailCard = ({ worship, wLoading }) => {
  if (wLoading) {
    return <Loader />;
  }

  const worshipDate = new Date(worship?.started_at);
  const today = new Date();

  return (
    <Card style={{ marginBottom: 16 }}>
      <CardContent>
        <Badge
          badgeContent={
            today.getDate() === worshipDate.getDate() ? 'HARI INI' : null
          }
          color="secondary"
          style={{ width: '93%' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography gutterBottom variant="h5" component="h2">
            {worship?.worship?.description}
          </Typography>
        </Badge>
        <Typography
          variant="subtitle1"
          component="p"
          color="textSecondary"
          style={{ marginBottom: 8 }}
        >
          {worship.description}
        </Typography>
        <Typography variant="button" component="p">
          Keterangan
        </Typography>
        <Typography variant="body2" component="p">
          {/* <strong>Tanggal:</strong> {format(worshipDate, 'dd MMM yyyy')} */}
        </Typography>
        <Typography variant="body2" component="p">
          {/* <strong>Jam:</strong> {format(worshipDate, 'hh:mm')} */}
        </Typography>
        <Typography variant="body2" component="p">
          <strong>Kapasitas:</strong> {worship.registered}/
          {worship.max_capacity} orang
        </Typography>
      </CardContent>
    </Card>
  );
};

const ListAttendances = ({ title, worshipID }) => {
  const { data: servants } = useServants(worshipID);

  return (
    <Card>
      <Box paddingX={2} paddingTop={2}>
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          {title}
        </Typography>
        <Divider style={{ marginTop: 8 }} />
      </Box>
      <List>
        {servants.map((attendance) => (
          <ListItem key={attendance.id}>
            <ListItemAvatar>
              <Avatar
                alt={attendance.user.name}
                src={getImage(attendance.user.picture, 100)}
              />
            </ListItemAvatar>
            <ListItemText>
              <Typography
                component="span"
                variant="caption"
                color="textPrimary"
                style={{ textTransform: 'uppercase' }}
              >
                {worshipRoleMapper[attendance.attendance_role]}
              </Typography>
              <Typography variant="body1">{attendance.user.name}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

const QRDialog = ({ open, data, worshipDate, handleClose }) => {
  const downloadQR = React.useRef(null);

  const handleDownload = (e) => {
    e.preventDefault();
    const qrImage = document.querySelector('#qrcode > img');

    downloadQR.current.setAttribute(
      'download',
      data.worships_schedule_id + '-' + paramCase(data.user.name) + '.png',
    );
    downloadQR.current.setAttribute(
      'href',
      qrImage.getAttribute('src').replace('image/png', 'image/octet-stream'),
    );
    downloadQR.current.click();
  };

  return (
    <React.Fragment>
      {data ? (
        <Dialog onClose={handleClose} open={open} fullWidth>
          <DialogTitle onClose={handleClose}>QRCode</DialogTitle>
          <DialogContent dividers style={{ textAlign: 'center', padding: 0 }}>
            <QRCode
              value={`${data.id}`}
              name={data.user.name}
              worshipDate={format(new Date(worshipDate), 'dd MMM yyyy')}
            />
          </DialogContent>
          <DialogActions>
            <a
              id="downloadQR"
              ref={downloadQR}
              href="/#"
              style={{ display: 'none' }}
            >
              &nbsp;
            </a>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDownload}
              style={{ margin: 0 }}
            >
              Download QR
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </React.Fragment>
  );
};

const Reservation = ({ autoOpen, worshipID, worship, wLoading }) => {
  const { data: family, onJoin, onCancel } = useFamilyInCharge(worshipID);

  const [open, setOpen] = React.useState(autoOpen || false);
  const [selected, setSelected] = React.useState();
  const now = new Date();

  if (wLoading) {
    return <Loader />;
  }

  return (
    <Box position="relative">
      {family && family.length > 0 ? (
        <Alert severity="info">
          Tap pada nama anggota keluarga untuk menampilkan QR Code
        </Alert>
      ) : null}
      {family.length ? (
        <Card style={{ marginTop: 16 }}>
          <List>
            {(family || []).map((attendance) => (
              <ListItem
                button
                key={attendance.id}
                onClick={() => setSelected(attendance)}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={attendance.user.name}
                    src={getImage(attendance.user.picture, 100)}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                    style={{ textTransform: 'uppercase' }}
                  >
                    {worshipRoleMapper[attendance.attendance_role]}
                  </Typography>
                  <Typography variant="body1">
                    {attendance.user.name}
                  </Typography>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textSecondary"
                  >
                    Status:{' '}
                    {attendance.arrived_at
                      ? `Hadir (${format(
                          new Date(attendance.arrived_at),
                          'dd MMM yyyy HH:mm',
                        )})`
                      : 'Belum Hadir'}
                  </Typography>
                </ListItemText>
                {attendance.attendance_role === 'JEMAAT' &&
                attendance.arrived_at === null ? (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        onCancel(attendance.id);
                      }}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                ) : null}
              </ListItem>
            ))}
          </List>
        </Card>
      ) : (
        <Empty text="Belum ada anggota keluarga yang melakukan reservasi pada ibadah ini" />
      )}
      {worship ? (
        <QRDialog
          open={!!selected}
          handleClose={() => {
            setSelected(null);
          }}
          worshipDate={worship.started_at}
          data={selected}
        />
      ) : null}
      <FamilyDialog
        open={open}
        onClose={() => setOpen(false)}
        onSelect={(result) => {
          onJoin(result).then((attendance) => {
            setSelected(attendance);
          });
          setOpen(false);
        }}
      />
      <Box position="fixed" bottom="0" left="0" right="0" padding={2}>
        <Box margin="auto" maxWidth="550px">
          <Button
            fullWidth
            disabled={
              now > new Date(worship.started_at) ||
              worship.registered >= worship.max_capacity
            }
            onClick={() => setOpen(true)}
            variant="contained"
            color="primary"
          >
            Reservasi
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const DetailWorship = ({ match }) => {
  const query = useQuery();
  const { data: worship, loading: wLoading } = useSchedule(match.params.id);
  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    if (query.get('tab') === 'reservation') {
      setTabIndex(1);
    }
  }, [query]);

  if (wLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <SecondaryNavigation title="Detail Ibadah" />
      <Box maxWidth="550px" marginX="auto">
        <Tabs
          value={tabIndex}
          onChange={(_, newIndex) => {
            setTabIndex(newIndex);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Detail" />
          {worship.worship_type === 'RSVP' ? <Tab label="Reservasi" /> : null}
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
          <WorshipDetailCard
            worshipID={worship.id}
            worship={worship}
            wLoading={wLoading}
          />
          <ListAttendances
            title="PETUGAS PELAYANAN"
            worshipID={worship.id}
            worship={worship}
            wLoading={wLoading}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Reservation
            worshipID={worship.id}
            autoOpen={query.get('modal') === '1'}
            worship={worship}
            wLoading={wLoading}
          />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};

export default DetailWorship;
