import React from 'react';
import startCase from 'lodash.startcase';
import GoogleMapReact from 'google-map-react';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Button,
  Typography,
} from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';

const titleCase = (str) => {
  return startCase(str.toLowerCase());
};

const MapMarker = ({ text }) => (
  <Box
    position="absolute"
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="20px"
    height="20px"
    left="5px"
    top="-10px"
    zIndex="9999"
  >
    <RoomIcon color="error" />
    <Typography>{text}</Typography>
  </Box>
);

function MediaCard({
  hideDeleteButton,
  name,
  address,
  province,
  regency,
  district,
  villages,
  latitude,
  longitude,
  handleEdit,
  handleDelete,
}) {
  return (
    <Card style={{ marginBottom: 16, width: '100%' }}>
      <CardActionArea>
        <Box height="200px" width="100%">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyC2kj2L7wUjVwDrRCP4k5o3RF5ZU5r6nwI',
            }}
            defaultCenter={[latitude, longitude]}
            defaultZoom={12}
          >
            <MapMarker lat={latitude} lng={longitude} text={name} />
          </GoogleMapReact>
        </Box>
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {`${address}, ${titleCase(district)}, ${titleCase(
              regency,
            )}, ${titleCase(villages)}, ${province}`}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={handleEdit}>
          Perbarui
        </Button>
        {hideDeleteButton ? null : (
          <Button size="small" color="primary" onClick={handleDelete}>
            Hapus
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export default MediaCard;
