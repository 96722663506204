import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Divider,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';

const PollsCard = (props) => {
  const history = useHistory();

  return (
    <Card style={{ marginBottom: 16 }}>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {props.title}
        </Typography>
        <Typography variant="body2" component="p" style={{ marginBottom: 8 }}>
          {props?.description?.split('\n').map((s, i) => (
            <p
              key={i}
              style={{ margin: 0 }}
              dangerouslySetInnerHTML={{ __html: s }}
            />
          ))}
        </Typography>
        <Typography variant="caption" color="textSecondary" component="p">
          <strong>Batas Akhir:</strong>{' '}
          {props.deadline ? format(props.deadline, 'dd MMM yyyy HH:mm a') : null}
        </Typography>
      </CardContent>
      <Divider />
      {props.id ? (
        <CardActions>
          <Button
            size="small"
            color="primary"
            fullWidth
            variant="outlined"
            onClick={() => {
              history.push(`/d/polls/${props.id}`);
            }}
          >
            Mulai Memilih
          </Button>
        </CardActions>
      ) : null}
    </Card>
  );
};

export default PollsCard;
