import React from 'react';
import {
  Box,
  Fab,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import { PrimaryNavigation, Loader, Empty, QRModal } from 'components';
import { useJemaat } from 'libs/hooks/jemaat';
import { getImage } from 'libs/utils/image';

const ListJemaat = () => {
  const history = useHistory();
  const { data: users, loading } = useJemaat();
  const [qrData, setQRData] = React.useState(null);

  return (
    <React.Fragment>
      <PrimaryNavigation title="Anggota Keluarga" />
      {users.length ? (
        <Box marginX="auto" maxWidth="550px">
          <List>
            {users.map((user, idx, arr) => {
              return (
                <React.Fragment key={idx}>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        color="primary"
                        aria-label="delete"
                        onClick={() => setQRData(user)}
                      >
                        <QrCode2Icon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={user.name}
                        src={getImage(user.picture, 100)}
                        onClick={() =>
                          history.push(`/d/jemaat/${user.user_sk}/detail`)
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.name}
                      onClick={() =>
                        history.push(`/d/jemaat/${user.user_sk}/detail`)
                      }
                    />
                  </ListItem>
                  {idx === arr.length - 1 ? null : (
                    <Divider variant="inset" component="li" />
                  )}
                </React.Fragment>
              );
            })}
          </List>
          {qrData ? (
            <QRModal
              handleClose={() => setQRData(null)}
              openModal={!!qrData}
              user={qrData}
            />
          ) : null}
          {loading ? <Loader /> : null}
        </Box>
      ) : (
        <Empty />
      )}
      <Fab
        aria-label="Tambah Jemaat"
        style={{ position: 'fixed', bottom: 30, right: 30 }}
        color="primary"
        onClick={() => {
          history.push('/d/jemaat/add');
        }}
      >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

export default ListJemaat;
