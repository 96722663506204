import { Box, Typography } from '@mui/material';

import notFoundPict from 'components/feedback/notfound.svg';

const Empty = ({ text }) => {
  return (
    <Box
      maxWidth="550px"
      margin="auto"
      textAlign="center"
      paddingX="36px"
      paddingTop="36px"
    >
      <img
        alt={text}
        style={{ maxWidth: '100%', width: 180, marginBottom: 16 }}
        src={notFoundPict}
      />
      <Typography>{text}</Typography>
    </Box>
  );
};

Empty.defaultProps = {
  text: 'Data belum tersedia.',
};

export default Empty;
