export const getImage = (url, width = 480, ratio = '1:1') => {
  const optimization = `/fl_progressive,fl_lossy,c_fill,g_face,q_auto:best,w_${width},ar_${ratio}`;
  const baseURL = 'https://res.cloudinary.com/gepembri-app/image/upload';
  const newURL =
    url.indexOf(baseURL) > -1
      ? url.substr(0, baseURL.length) +
        optimization +
        url.substr(baseURL.length)
      : url;
  return newURL;
};
