import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  ListSubheader,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { CommisionDialog, Loader } from 'components';
import { getImage } from 'libs/utils/image';
import { useUserCommision } from 'libs/hooks/commision';

const UserCommition = ({ userID }) => {
  const [open, toggleOpenDialog] = React.useState(false);
  const {
    data: commisions,
    loading,
    onJoin,
    onLeave,
  } = useUserCommision(userID);

  const handleCloseDialog = () => {
    toggleOpenDialog(false);
  };

  const handleLeaveCommision = (data) => {
    onLeave(data);
  };

  const handleSubmitDialog = (commisionID) => {
    onJoin(commisionID);
    toggleOpenDialog(false);
  };

  return (
    <React.Fragment>
      <List subheader={<ListSubheader>Persekutuan</ListSubheader>}>
        <ListItem>
          <Tooltip
            title="Mohon tambahkan persekutuan yang sering diikuti"
            open={commisions.length === 0}
            arrow
            placement="top"
          >
            <Button
              color="primary"
              fullWidth
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => toggleOpenDialog(true)}
            >
              Ikut Persekutuan
            </Button>
          </Tooltip>
        </ListItem>
        {loading ? <Loader /> : null}
        {commisions.map((ucom) => {
          return (
            <ListItem key={ucom.id}>
              <ListItemAvatar>
                <Avatar
                  alt={ucom.commision.name}
                  src={getImage(ucom.commision.picture, 100)}
                />
              </ListItemAvatar>
              <ListItemText>
                <Typography>{ucom.commision.name}</Typography>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {ucom.commision.description}
                </Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleLeaveCommision(ucom.id)}
                  size="large">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <CommisionDialog
        open={open}
        onClose={handleCloseDialog}
        onSelect={handleSubmitDialog}
      />
    </React.Fragment>
  );
};

export default UserCommition;
