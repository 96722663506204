const base = {
  COOKIE_NAME: 'gepembri-app',
  LAST_VISIT: 'last-visit',
  JEMAAT_FOLDER: 'jemaat_staging',
};

const development = (function () {
  let apiHost = 'http://localhost:8000';

  if (process.env.REACT_APP_API_HOST) {
    apiHost = process.env.REACT_APP_API_HOST;
  }

  return {
    ...base,
    API_HOST: apiHost,
    GOOGLE_CLIENT_ID:
      '876245592931-alrcca5ndcr7cjdgqvhn0acs4ftlssrv.apps.googleusercontent.com',
  };
})();

const staging = {
  ...base,
  API_HOST: process.env.REACT_APP_API_HOST,
  GOOGLE_CLIENT_ID:
    '876245592931-alrcca5ndcr7cjdgqvhn0acs4ftlssrv.apps.googleusercontent.com',
};

const production = {
  ...base,
  API_HOST: process.env.REACT_APP_API_HOST,
  GOOGLE_CLIENT_ID:
    '876245592931-vmhpffvfqucr8bv7djoi9k4rul1atn2o.apps.googleusercontent.com',
  JEMAAT_FOLDER: 'jemaat',
};

const env = process.env.REACT_APP_STAGE || 'development';
export default env === 'production'
  ? production
  : env === 'staging'
  ? staging
  : development;
