import React from 'react';
import { useHistory } from 'react-router-dom';
import { Fab, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
  PrimaryNavigation,
  Loader,
  Empty,
  AddressCard,
  Dialog,
} from 'components';
import { useAddress } from 'libs/hooks/address';

const AlamatJemaat = () => {
  const history = useHistory();
  const { data: addresses, loading, onDelete } = useAddress();
  const [deleteID, setDeletedID] = React.useState(null);

  const handleDelete = () => {
    onDelete(deleteID);
  };

  return (
    <React.Fragment>
      <PrimaryNavigation title="Alamat" />
      <Box maxWidth="550px" marginX="auto">
        <Box padding={2}>
          {addresses.map((addr) => {
            return (
              <AddressCard
                key={addr.id}
                {...addr}
                handleDelete={() => setDeletedID(addr.id)}
                handleEdit={() => history.push(`/d/address/${addr.id}/edit`)}
              />
            );
          })}
          {loading ? <Loader /> : null}
          {addresses.length === 0 && !loading ? <Empty /> : null}
        </Box>
      </Box>
      <Dialog
        title="Hapus alamat ini?"
        content="Pastikan alamat sudah tidak digunakan oleh anggota keluarga anda terlebih dahulu. Alamat yang sudah terhapus tidak dapat dikembalikan"
        open={!!deleteID}
        handleClose={() => setDeletedID(null)}
        cancelButton="Batal"
        okButton="Hapus"
        handleOK={handleDelete}
      />
      <Fab
        aria-label="Tambah Alamat Jemaat"
        style={{ position: 'fixed', bottom: 30, right: 30 }}
        color="primary"
        onClick={() => {
          history.push('/d/address/add');
        }}
      >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

export default AlamatJemaat;
