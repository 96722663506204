import React from 'react';
import { Route } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  ListJemaat,
  AddJemaat,
  DetailJemaat,
  EditJemaat,

} from 'pages/User';
import {
  UserAddressList,
  UserAddressAdd,
  UserAddressEdit,
} from 'pages/Address';
import {
  WorshipList,
  WorshipDetail,
  WorshipAbsentRSVP,
  WorshipAbsentOnSite,
} from 'pages/Worship';
import { ListPolls, DetailPolls, DetailVoting } from 'pages/Polls';

const App = () => {
  return (
    <React.Fragment>
      <Box>
        <Route exact path="/d" component={WorshipList} />
        <Route exact path="/d/worships/:id" component={WorshipDetail} />
        <Route
          exact
          path="/d/worships/:id/absent"
          component={WorshipAbsentRSVP}
        />
        <Route
          exact
          path="/d/worships/:id/onsite-absent"
          component={WorshipAbsentOnSite}
        />
        <Route exact path="/d/jemaat" component={ListJemaat} />
        <Route exact path="/d/jemaat/add" component={AddJemaat} />
        <Route
          exact
          path="/d/jemaat/:user_sk/detail"
          component={DetailJemaat}
        />
        <Route exact path="/d/jemaat/:user_sk/edit" component={EditJemaat} />

        <Route exact path="/d/address" component={UserAddressList} />
        <Route exact path="/d/address/add" component={UserAddressAdd} />
        <Route exact path="/d/address/:id/edit" component={UserAddressEdit} />
        <Route exact path="/d/polls" component={ListPolls} />
        <Route exact path="/d/polls/:id" component={DetailPolls} />
        <Route
          exact
          path="/d/polls/:id/vote/:voter_id"
          component={DetailVoting}
        />
      </Box>
    </React.Fragment>
  );
};

export default App;
