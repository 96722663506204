import useSWR, { mutate } from 'swr';
import { useState } from 'react';

import api from 'libs/utils/api';
import useToast from 'libs/contexts/toasts';

export const useUserCommision = (userID) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const pathKey = `/commision-members/${userID}`;
  const {
    data = [],
    error,
    isValidating,
  } = useSWR(() => (userID ? pathKey : null));

  const onJoin = async (commisionID) => {
    setLoading(true);
    try {
      const { data: res } = await api.post(`/commision-members/${userID}`, {
        commision_id: commisionID,
      });
      if (res.success) {
        mutate(pathKey);
        toast('success', 'Data berhasil disimpan.');
      } else {
        toast('error', 'Gagal menyimpan data.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal menyimpan data.');
    } finally {
      setLoading(false);
    }
  };

  const onLeave = async (userCommisionID) => {
    setLoading(true);
    try {
      const { data: res } = await api.delete(`/commision-members/`, {
        data: [userCommisionID],
      });
      if (res.success) {
        mutate(pathKey);
        toast('success', 'Data berhasil dihapus.');
      } else {
        toast('error', 'Gagal menghapus data.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal menghapus data.');
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading: (!error && !data) || isValidating || loading,
    onJoin,
    onLeave,
  };
};
