import React, { createContext, useState, useCallback, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import get from 'lodash.get';

import config from 'config';
import api from 'libs/utils/api';
import useToast from 'libs/contexts/toasts';

const AuthContext = createContext({
  loading: false,
  isAuthenticated: false,
  user: null,
  authenticate: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const toast = useToast();
  const [cookies, setCookie, removeCookie] = useCookies([config.COOKIE_NAME]);
  const { data: user } = useSWR(() =>
    cookies.access_token ? '/auth/me' : null,
  );
  const [loading, setLoading] = useState(false);

  const authenticate = useCallback(
    async (res, church) => {
      setLoading(true);

      const authCode = get(res, 'code', null);
      if (!authCode) {
        console.error('Auth code not found.')
        return;
      }

      try {
        const { data: res } = await api.post('/auth/', {
          id_token: authCode,
          church_id: church || null,
        });

        if (res?.token) {
          const today = new Date();
          const nextTenYear = new Date();
          nextTenYear.setFullYear(today.getFullYear() + 10);

          setCookie('access_token', res.token, {
            expires: nextTenYear,
            path: '/',
          });

          setTimeout(() => {
            const lastVisit =
              window.localStorage.getItem(config.LAST_VISIT) || '/d';

            history.push(lastVisit);

            window.localStorage.removeItem(config.LAST_VISIT);

            mutate('/auth/me');
          }, 500);
        } else {
          toast('error', 'Terjadi kesalahan.');
        }
      } catch (error) {
        toast('error', error);
      } finally {
        setLoading(false);
      }
    },
    [history, toast, setCookie],
  );

  const logout = useCallback(() => {
    removeCookie('access_token');
    history.replace('/');
  }, [history, removeCookie]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, authenticate, loading, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
