import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Box,
  Button,
  TextField,
  Divider,
  Typography,
  FormHelperText,
  FormControl,
} from '@mui/material';

import { SecondaryNavigation, DragableMaps, Loader, Empty } from 'components';
import { useAutocompleteLocation } from 'libs/hooks/location';
import { useAddressDetail } from 'libs/hooks/address';
import countries from 'pages/Address/coutries.json';

const UbahAlamatJemaat = ({ match }) => {
  const {
    data: address,
    loading,
    onEdit,
  } = useAddressDetail({
    addressID: match.params.id,
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const [options, setQuery] = useAutocompleteLocation();
  const [selectedLoc, setSelectedLoc] = useState({
    country: '',
    province: '',
    regency: '',
    district: '',
    villages: '',
  });

  useEffect(() => {
    if (address) {
      setSelectedLoc({
        country: address.country,
        province: address.province,
        regency: address.regency,
        district: address.district,
        villages: address.villages,
      });
    }
  }, [address]);

  useEffect(() => {
    if (address) {
      reset({
        name: address.name,
        address: address.address,
        country: address.country,
        province: address.province,
        regency: address.regency,
        district: address.district,
        villages: address.villages,
        longitude: address.longitude,
        latitude: address.latitude,
      });
    }
  }, [address]); // eslint-disable-line

  const onSubmit = (values) => {
    onEdit(values);
  };

  const handleDragEnd = (coords) => {
    setValue('latitude', coords.lat);
    setValue('longitude', coords.lng);
  };

  const { ref: nameRef, ...nameRest } = register('name', { required: true });
  const { ref: addressRef, ...addressRest } = register('address', {
    required: true,
  });
  const { ref: provinceRef, ...provinceRest } = register('province', {
    required: true,
  });
  const { ref: regencyRef, ...regencyRest } = register('regency', {
    required: true,
  });
  const { ref: districtRef, ...districtRest } = register('district', {
    required: true,
  });
  const { ref: villagesRef, ...villagesRest } = register('villages', {
    required: true,
  });

  return (
    <React.Fragment>
      <SecondaryNavigation title="Ubah Alamat" />
      {loading ? <Loader /> : null}
      {!address ? (
        <Empty />
      ) : (
        <Box marginX="auto" padding={2} maxWidth="550px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              fullWidth
              name="name"
              label="Alamat disimpan sebagai:"
              margin="normal"
              error={errors?.name}
              InputLabelProps={{
                shrink: true,
              }}
              {...nameRest}
              inputRef={nameRef}
            />
            <TextField
              fullWidth
              name="address"
              label="Alamat"
              margin="normal"
              error={errors?.address}
              InputLabelProps={{
                shrink: true,
              }}
              {...addressRest}
              inputRef={addressRef}
            />

            <Box marginBottom={1}>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <Autocomplete
                  options={countries}
                  name="country"
                  autoComplete
                  defaultValue={{ name: address.country, code: 'ID' }}
                  getOptionLabel={(option) => option.name}
                  includeInputInList
                  onChange={(_, value) => {
                    if (value) {
                      setSelectedLoc({ ...selectedLoc, country: value.name });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField label="Negara" fullWidth {...params} />
                  )}
                />
              </FormControl>
              <input
                type="hidden"
                name="country"
                defaultValue={selectedLoc.country}
                value={selectedLoc.country}
                {...register('country', { required: true })}
              />
              <input
                type="hidden"
                name="province"
                value={selectedLoc.province}
                {...register('province', { required: true })}
              />
              <input
                type="hidden"
                name="regency"
                value={selectedLoc.regency}
                {...register('regency', { required: true })}
              />
              <input
                type="hidden"
                name="district"
                value={selectedLoc.district}
                {...register('district', { required: true })}
              />
              <input
                type="hidden"
                name="villages"
                value={selectedLoc.villages}
                {...register('villages', { required: true })}
              />
            </Box>
            {selectedLoc.country && selectedLoc.country === 'Indonesia' ? (
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <Autocomplete
                  getOptionLabel={(option) => option.location}
                  options={options}
                  autoComplete
                  includeInputInList
                  defaultValue={{
                    location: `${address.province}, ${address.regency}, ${address.district}, ${address.villages}`,
                  }}
                  onChange={(_, value) => {
                    if (value) {
                      const getName = (field) =>
                        value.detail.find((d) => d.type === field).name || null;

                      setSelectedLoc({
                        ...selectedLoc,
                        province: getName('provinces'),
                        regency: getName('regencies'),
                        district: getName('districts'),
                        villages: getName('villages'),
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ketik Nama Kelurahan"
                      fullWidth
                      onChange={(e) => {
                        setQuery(e.target.value);
                      }}
                    />
                  )}
                />
              </FormControl>
            ) : null}
            {selectedLoc.country && selectedLoc.country !== 'Indonesia' ? (
              <React.Fragment>
                <Box display="flex" justifyContent="space-between">
                  <TextField
                    name="province"
                    margin="normal"
                    label="Provinsi"
                    style={{ minWidth: '48%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors?.province}
                    onChange={(e) =>
                      setSelectedLoc({
                        ...selectedLoc,
                        province: e.target.value,
                      })
                    }
                    {...provinceRest}
                    inputRef={provinceRef}
                  />
                  <TextField
                    name="regency"
                    margin="normal"
                    label="Kabupaten/Kota"
                    style={{ minWidth: '48%', marginLeft: 16 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors?.regency}
                    onChange={(e) =>
                      setSelectedLoc({
                        ...selectedLoc,
                        regency: e.target.value,
                      })
                    }
                    {...regencyRest}
                    inputRef={regencyRef}
                  />
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <FormControl style={{ minWidth: '100%' }} margin="normal">
                    <TextField
                      name="district"
                      margin="normal"
                      label="Kecamatan"
                      style={{ minWidth: '48%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.district}
                      onChange={(e) =>
                        setSelectedLoc({
                          ...selectedLoc,
                          district: e.target.value,
                        })
                      }
                      {...districtRest}
                      inputRef={districtRef}
                    />
                  </FormControl>
                  <FormControl style={{ minWidth: '100%' }} margin="normal">
                    <TextField
                      name="villages"
                      margin="normal"
                      label="Kelurahan/Desa"
                      style={{ minWidth: '48%', marginLeft: 16 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.villages}
                      onChange={(e) =>
                        setSelectedLoc({
                          ...selectedLoc,
                          villages: e.target.value,
                        })
                      }
                      {...villagesRest}
                      inputRef={villagesRef}
                    />
                  </FormControl>
                </Box>
              </React.Fragment>
            ) : null}
            <Typography
              gutterBottom
              variant="h6"
              component="h3"
              style={{ marginTop: 16 }}
            >
              Pilih di peta:
            </Typography>
            {errors?.longitude ? (
              <FormHelperText>Mohon pilih lokasi anda di peta</FormHelperText>
            ) : null}
            <DragableMaps
              onDragEnd={handleDragEnd}
              latitude={address.latitude}
              longitude={address.longitude}
            />
            <Divider style={{ margin: '16px 0' }} />
            <input
              type="hidden"
              name="latitude"
              defaultValue={address.latitude}
              {...register('latitude', { required: true })}
            />
            <input
              type="hidden"
              name="longitude"
              defaultValue={address.longitude}
              {...register('longitude', { required: true })}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: 16 }}
              type="submit"
            >
              Simpan
            </Button>
          </form>
        </Box>
      )}
    </React.Fragment>
  );
};

export default UbahAlamatJemaat;
