import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const MyDialog = ({
  open,
  title,
  content,
  cancelButton,
  okButton,
  handleClose,
  handleOK,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {cancelButton}
        </Button>
        <Button
          onClick={() => {
            handleOK();
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          {okButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MyDialog.defaultProps = {
  cancelButton: 'Cancel',
  okButton: 'OK',
};

export default MyDialog;
