import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  ListSubheader,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { RelationDialog, Loader } from 'components';
import { getImage } from 'libs/utils/image';
import { useUserRelation } from 'libs/hooks/relationship';
import { relationMapper } from '../utils';

const UserRelation = ({ userID }) => {
  const {
    data: relationships,
    loading,
    onAdd,
    onConfirm,
    onDelete,
  } = useUserRelation(userID);
  const [open, toggleOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    toggleOpenDialog(false);
  };

  const handleSubmitDialog = (data) => {
    onAdd({
      relativeID: data.userID,
      relationshipType: data.relationshipType,
    });
    toggleOpenDialog(false);
  };

  return (
    <React.Fragment>
      <List subheader={<ListSubheader>Relasi</ListSubheader>}>
        <ListItem>
          <Button
            color="primary"
            fullWidth
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => toggleOpenDialog(true)}
          >
            Tambah Relasi
          </Button>
        </ListItem>
        {loading ? <Loader /> : null}
        {relationships.map((rel) => {
          return (
            <ListItem key={rel.id}>
              <ListItemAvatar>
                <Avatar
                  alt={rel.relative.name}
                  src={getImage(rel.relative.picture, 100)}
                />
              </ListItemAvatar>
              <ListItemText
                secondary={
                  rel.status === 'NEED_CONFIRM' ? (
                    <span style={{ marginTop: 16, display: 'block' }}>
                      <Button
                        edge="end"
                        aria-label="confirm"
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => onConfirm(rel.relative.id)}
                      >
                        Konfirmasi
                      </Button>
                      <Button
                        edge="end"
                        aria-label="decline"
                        color="secondary"
                        variant="contained"
                        size="small"
                        style={{ marginLeft: 8 }}
                        onClick={() => onDelete(rel.relative.id)}
                      >
                        Tolak
                      </Button>
                    </span>
                  ) : null
                }
              >
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {relationMapper[rel.relationship_type]}
                </Typography>
                <Typography>{rel.relative.name}</Typography>
                <Typography variant="caption">
                  {rel.status === 'PENDING' ? 'Menunggu konfirmasi' : null}
                </Typography>
                {rel.status === 'NEED_CONFIRM' ? (
                  <Typography variant="caption">
                    menambahkan anda sebagai relasinya
                  </Typography>
                ) : null}
              </ListItemText>
              <ListItemSecondaryAction>
                {rel.status === 'NEED_CONFIRM' ? null : (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => onDelete(rel.relative.id)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <RelationDialog
        open={open}
        user={userID}
        onClose={handleCloseDialog}
        onSelect={handleSubmitDialog}
      />
    </React.Fragment>
  );
};

export default UserRelation;
