import React from 'react';
import debounce from 'lodash.debounce';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from '@mui/material';
import { Autocomplete } from '@mui/material';

import api from 'libs/utils/api';

const UserCommisionDialog = ({ open, onClose, onSelect }) => {
  const [commisionID, setCommisionID] = React.useState(null);
  const [options, setOptions] = React.useState([]);

  const fetchData = debounce((name) => {
    api
      .get(`/commisions/?name=${name}&page=1&limit=5`)
      .then(({ data: res }) => {
        setOptions(res.data);
      })
      .catch(() => setOptions([]));
  }, 400);

  const handleSearchUser = ({ target }) => {
    if (target.value) {
      fetchData(target.value);
    } else {
      setOptions([]);
    }
  };

  return <>
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="select-relationship-dialog-title"
    >
      <DialogTitle id="select-relationship-dialog-title">
        Ikuti Persekutuan
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Pilih persekutuan yang sering anda ikuti.
        </DialogContentText>
        <FormControl fullWidth style={{ marginBottom: 16 }}>
          <Autocomplete
            onChange={(_, selected) => {
              if (selected) {
                setCommisionID(selected.id);
              }
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ketik nama komisi..."
                fullWidth
                onChange={handleSearchUser}
                InputProps={params.InputProps}
              />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions style={{ padding: '8px 16px' }}>
        <Button onClick={() => onClose()}>Batal</Button>
        <Button
          disabled={!commisionID}
          onClick={() => {
            onSelect(commisionID);
          }}
          color="primary"
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default UserCommisionDialog;
