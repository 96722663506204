import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import format from 'date-fns/format';
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Divider,
  FormHelperText,
  FormControlLabel,
  Switch,
  Paper,
  Typography,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';

import config from 'config';
import { SecondaryNavigation } from 'components';
import useToast from 'libs/contexts/toasts';
import uploadImage from 'libs/utils/uploadImage';
import { getImage } from 'libs/utils/image';

import occupations from 'pages/User/occupations.json';
import { useJemaat } from 'libs/hooks/jemaat';

const TambahJemaat = () => {
  const toast = useToast();
  const { handleSubmit, register, errors, control, setValue, watch } =
    useForm();
  const { loading, onAdd } = useJemaat();

  const [isUploading, setUploadStatus] = React.useState(false);
  const [picturePreview, setPreview] = React.useState(
    window.localStorage.getItem('picture') || '',
  );

  // @todo: create upload component
  const handleUpload = ({ target }) => {
    setUploadStatus(true);
    uploadImage(target.files[0], { folder: config.JEMAAT_FOLDER })
      .then((res) => {
        setValue('picture', res.secure_url);
        setPreview(res.secure_url);
        window.localStorage.setItem('picture', res.secure_url);
      })
      .catch((err) => {
        toast('errror', `Gagal menunggah foto!. Kode:${err}`);
      })
      .finally(() => {
        setUploadStatus(false);
      });
  };

  const onSubmit = (values) => {
    onAdd(values);
    window.localStorage.removeItem('picture');
  };

  React.useEffect(() => {
    register('occupation');
  }, [register]);

  const { ref: nameRef, ...nameRest } = register('name', { required: true });
  const { ref: nickNameRef, ...nickNameRest } = register('nickname', {
    required: true,
  });
  const { ref: zhNameRef, ...zhNameRest } = register('mandarin_name');
  const { ref: memberNoRef, ...memberNoRest } = register('member_reg_no');
  const { ref: pobRef, ...pobRest } = register('birth_place', {
    required: true,
  });
  const { ref: phoneRef, ...phoneRest } = register('phone');
  const { ref: fowRef, ...fowRest } = register('field_of_work');
  const { ref: baptiseChurchRef, ...baptiseChurchRest } =
    register('baptise_church');
  const { ref: churchOrigRef, ...churchOrigRest } = register('church_origin');
  const isTemp = watch('membership') === 'TEMPORARY';
  const isBaptise = watch('is_baptised');

  return (
    <React.Fragment>
      <SecondaryNavigation title="Tambah Anggota" />
      <Box marginX="auto" maxWidth="550px" padding={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper style={{ padding: 16, marginBottom: 16 }}>
            <Typography variant="h6" component="h3" marginBottom={2}>
              Profil Jemaat
            </Typography>

            <Box>
              {picturePreview ? (
                <Box
                  sx={{ justifyContent: 'center', display: 'flex' }}
                  marginBottom={4}
                >
                  <Avatar
                    variant="circular"
                    sx={{ width: 200, height: 200 }}
                    alt="Uploads"
                    src={getImage(picturePreview)}
                  />
                </Box>
              ) : null}

              <label htmlFor="input-file">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  {isUploading ? 'Sedang mengunggah...' : 'Upload Foto'}
                </Button>
              </label>
              <FormHelperText style={{ marginTop: 16 }}>
                Ukuran foto maksimal 8MB
              </FormHelperText>
              <input
                accept="image/*"
                id="input-file"
                type="file"
                style={{ visibility: 'hidden' }}
                onChange={handleUpload}
                onClick={(e) => (e.target.value = null)}
              />
            </Box>
            <input
              name="picture"
              type="text"
              style={{ display: 'none' }}
              value={picturePreview}
              onChange={() => {}}
              {...register('picture', { required: true })}
            />
            {errors?.picture ? (
              <FormHelperText error style={{ marginBottom: 16 }}>
                Foto tidak boleh kosong!
              </FormHelperText>
            ) : null}

            <Divider style={{ marginBottom: '16px' }} />

            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <TextField
                fullWidth
                name="name"
                label="Nama"
                helperText="Nama sesuai KTP"
                error={errors?.name}
                inputRef={nameRef}
                InputLabelProps={{
                  shrink: true,
                }}
                {...nameRest}
              />
            </FormControl>
            <Box display="flex" justifyContent="space-between">
              <FormControl margin="normal">
                <TextField
                  name="nickname"
                  label="Nama Panggilan"
                  error={errors?.nickname}
                  inputRef={nickNameRef}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...nickNameRest}
                />
              </FormControl>

              <FormControl
                style={{ minWidth: '48%', marginLeft: '16px' }}
                margin="normal"
              >
                <TextField
                  name="mandarin_name"
                  label="Nama Mandarin"
                  helperText="Kosongkan jika tidak ada"
                  error={errors?.mandarin_name}
                  inputRef={zhNameRef}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...zhNameRest}
                />
              </FormControl>
            </Box>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <TextField
                fullWidth
                name="member_reg_no"
                label="No Anggota"
                helperText="Kosongkan jika tidak ada"
                error={errors?.member_reg_no}
                inputRef={memberNoRef}
                InputLabelProps={{
                  shrink: true,
                }}
                {...memberNoRest}
              />
            </FormControl>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <InputLabel>Jenis Kelamin</InputLabel>
              <Controller
                render={({ field }) => (
                  <Select {...field} label="Jenis Kelamin">
                    <MenuItem value="MALE">Laki-Laki</MenuItem>
                    <MenuItem value="FEMALE">Perempuan</MenuItem>
                  </Select>
                )}
                name="gender"
                control={control}
                defaultValue="FEMALE"
              />
            </FormControl>
            <Box display="flex" justifyContent="space-between">
              <FormControl margin="normal">
                <TextField
                  name="birth_place"
                  label="Tempat Lahir"
                  helperText="Tempat lahir sesuai KTP"
                  error={errors?.birth_place}
                  inputRef={pobRef}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...pobRest}
                />
              </FormControl>
              <FormControl
                style={{ minWidth: '48%', marginLeft: '16px' }}
                margin="normal"
              >
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <MobileDatePicker
                      sx={{
                        minWidth: '48%',
                        marginTop: '16px',
                        marginLeft: '16px',
                      }}
                      label="Tanggal Lahir"
                      inputFormat="dd/MM/yyyy"
                      onChange={(value) =>
                        onChange(format(value, 'MM/dd/yyyy'))
                      }
                      value={value}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )}
                  name="date_of_birth"
                  control={control}
                  defaultValue={new Date()}
                />
              </FormControl>
            </Box>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <TextField
                fullWidth
                name="phone"
                label="No. HP/WA"
                helperText="Tambahkan kode negara. (Contoh: 628123456789)"
                type="number"
                inputRef={phoneRef}
                InputLabelProps={{
                  shrink: true,
                }}
                {...phoneRest}
              />
            </FormControl>
          </Paper>
          <Paper style={{ padding: 16, marginBottom: 16 }}>
            <Typography
              variant="h6"
              component="h3"
              style={{ marginBottom: 16 }}
            >
              Data Lain
            </Typography>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <Autocomplete
                fullWidth
                options={occupations}
                getOptionLabel={(option) => {
                  return option;
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Pekerjaan" />
                )}
                onSelect={({ target }) => setValue('occupation', target.value)}
                defaultValue={'Belum / Tidak Bekerja'}
              />
            </FormControl>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <TextField
                fullWidth
                name="field_of_work"
                label="Bidang Pekerjaan/Perusahaan"
                inputRef={fowRef}
                InputLabelProps={{
                  shrink: true,
                }}
                {...fowRest}
              />
            </FormControl>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <InputLabel shrink>Status Baptis</InputLabel>
              <Controller
                name="is_baptised"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    style={{ marginTop: 16 }}
                    control={
                      <Switch
                        color="primary"
                        onChange={({ target }) => onChange(target.checked)}
                        checked={value}
                      />
                    }
                    label="Sudah Baptis"
                  />
                )}
              />
            </FormControl>
            {isBaptise ? (
              <Box display="flex" justifyContent="space-between">
                <FormControl margin="normal">
                  <TextField
                    fullWidth
                    name="baptise_church"
                    label="Gereja tempat pembaptisan"
                    inputRef={baptiseChurchRef}
                    {...baptiseChurchRest}
                  />
                </FormControl>
                <FormControl
                  style={{ minWidth: '48%', marginLeft: '16px' }}
                  margin="normal"
                >
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <MobileDatePicker
                        sx={{
                          minWidth: '100%',
                        }}
                        views={['year']}
                        label="Tahun Baptis"
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          onChange(format(value, '01/01/yyyy'))
                        }
                        value={value}
                      />
                    )}
                    name="baptise_year"
                    control={control}
                    defaultValue={new Date()}
                  />
                </FormControl>
              </Box>
            ) : null}
            <Box display="flex" justifyContent="space-between">
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <InputLabel>Status Perkawinan</InputLabel>
                <Controller
                  render={({ field }) => (
                    <Select {...field} label="Status Perkawinan">
                      <MenuItem value="SINGLE">Belum Menikah</MenuItem>
                      <MenuItem value="MARRIED">Menikah</MenuItem>
                      <MenuItem value="DIVORCED">Cerai</MenuItem>
                      <MenuItem value="WIDOWER">Duda</MenuItem>
                      <MenuItem value="WIDOWED">Janda</MenuItem>
                    </Select>
                  )}
                  name="marital_status"
                  control={control}
                  defaultValue={'SINGLE'}
                />
              </FormControl>
            </Box>

            <Box>
              <FormControl style={{ minWidth: '100%' }} margin="normal">
                <InputLabel>Status Keanggotaan</InputLabel>
                <Controller
                  render={({ field }) => (
                    <Select {...field} label="Status Keanggotaan">
                      <MenuItem value="PERMANENT">Tetap</MenuItem>
                      <MenuItem value="TEMPORARY">Simpatisan</MenuItem>
                    </Select>
                  )}
                  name="membership"
                  control={control}
                  defaultValue="PERMANENT"
                />
              </FormControl>

              {isTemp ? (
                <FormControl style={{ minWidth: '100%' }} margin="normal">
                  <TextField
                    fullWidth
                    name="church_origin"
                    label="Gereja Asal"
                    helperText="Mohon diisi jika anda belum sebagai anggota tetap Gepembri"
                    inputRef={churchOrigRef}
                    {...churchOrigRest}
                  />
                </FormControl>
              ) : null}
            </Box>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <MobileDatePicker
                    sx={{
                      minWidth: '100%',
                    }}
                    views={['year', 'month']}
                    label="Bergabung Sejak"
                    onChange={(value) => onChange(format(value, 'MM/01/yyyy'))}
                    value={value}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
                name="join_date"
                control={control}
                defaultValue={new Date()}
              />
            </FormControl>
          </Paper>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h6" component="h3">
              Info Donor
            </Typography>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <Controller
                name="available_for_donor"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    style={{ marginTop: 16 }}
                    control={
                      <Switch
                        color="primary"
                        onChange={({ target }) => onChange(target.checked)}
                        checked={value}
                      />
                    }
                    label="Bersedia Dihubungi Jika Diperlukan"
                  />
                )}
              />
            </FormControl>

            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <InputLabel>Golongan Darah</InputLabel>
              <Controller
                render={({ field }) => (
                  <Select {...field} label="Golongan Darah">
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                    <MenuItem value="O">O</MenuItem>
                    <MenuItem value="AB">AB</MenuItem>
                    <MenuItem value="X">Saya Tidak/Belum Tahu</MenuItem>
                  </Select>
                )}
                name="blood_type"
                control={control}
                defaultValue={'X'}
              />
            </FormControl>
            <FormControl style={{ minWidth: '100%' }} margin="normal">
              <InputLabel>Rhesus Darah</InputLabel>
              <Controller
                render={({ field }) => (
                  <Select {...field} label="Rhesus Darah">
                    <MenuItem value="POSITIVE">Positif</MenuItem>
                    <MenuItem value="NEGATIVE">Negatif</MenuItem>
                    <MenuItem value="X">Saya Tidak/Belum Tahu</MenuItem>
                  </Select>
                )}
                name="blood_rhesus"
                control={control}
                defaultValue={'X'}
              />
            </FormControl>
          </Paper>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 3, marginBottom: 4 }}
            type="submit"
            startIcon={<SaveIcon />}
          >
            {loading ? 'Loading...' : 'Simpan'}
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
};

export default TambahJemaat;
