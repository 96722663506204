import React from 'react';
import { render } from 'react-dom';
import { SWRConfig } from 'swr';
import * as Sentry from '@sentry/react';
import { CookiesProvider } from 'react-cookie';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { indigo, pink } from '@mui/material/colors';
import { GoogleOAuthProvider } from '@react-oauth/google';

import config from 'config';
import App from 'App';
import Login from 'pages/Login';
import { PrivateRoute } from 'components';
import * as serviceWorker from 'serviceWorker';
import { fetcher } from 'libs/utils/api';
import { ToastProvider } from 'libs/contexts/toasts';
import { AuthProvider } from 'libs/contexts/auth';

const Container = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: indigo[500],
      },
      secondary: {
        main: pink[500],
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CookiesProvider>
            <CssBaseline />
            <SWRConfig
              value={{
                refreshInterval: 0,
                fetcher,
              }}
            >
              <ToastProvider>
                <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
                  <Router>
                    <AuthProvider>
                      <Switch>
                        <Route exact path="/" component={Login} />
                        <PrivateRoute path="/d" component={App} />
                      </Switch>
                    </AuthProvider>
                  </Router>
                </GoogleOAuthProvider>
              </ToastProvider>
            </SWRConfig>
          </CookiesProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const renderApp = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://d762c32c7585410e8371d5a8c6ed3965@o419200.ingest.sentry.io/5329948',
    });
  }

  const container = document.getElementById('root');
  render(<Container />, container);
};

serviceWorker.unregister();

renderApp();

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}
