import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
  ListItem,
  Dialog,
  DialogTitle,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RoomIcon from '@mui/icons-material/Room';

import { useAddress } from 'libs/hooks/address';

const AddressDialog = ({ onSelect, onClose, open }) => {
  const history = useHistory();
  const { data: addresses, loading } = useAddress();

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onSelect(value);
    onClose();
  };

  if (loading) {
    return null;
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="address-selection-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Pilih Alamat</DialogTitle>
      <List>
        {addresses.map((address) => (
          <ListItem
            key={address.id}
            button
            onClick={() => handleListItemClick(address.id)}
          >
            <ListItemAvatar>
              <Avatar>
                <RoomIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={address.name} />
          </ListItem>
        ))}

        <ListItem
          autoFocus
          button
          onClick={() => history.push('/d/address/add')}
        >
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Tambah Alamat" />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default AddressDialog;
