import React from 'react';
import format from 'date-fns/format';
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fab,
  TextField,
} from '@mui/material';
import { Alert } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import { QRCode, QRCodeUser, Empty } from 'components';
import { getImage } from 'libs/utils/image';
import { worshipRoleMapper } from 'pages/Worship/utils';
import { useSchedule } from 'libs/hooks/schedules';

const QRDialog = ({ open, isRSVP, data, worshipDate, handleClose }) => {
  return (
    <React.Fragment>
      {data ? (
        <Dialog onClose={handleClose} open={open} fullWidth>
          <DialogTitle onClose={handleClose}>QRCode</DialogTitle>
          <DialogContent dividers style={{ textAlign: 'center', padding: 0 }}>
            {isRSVP ? (
              <QRCode
                value={`${data.id}`}
                name={data.user.name}
                worshipDate={format(new Date(worshipDate), 'dd MMM yyyy')}
              />
            ) : (
              <QRCodeUser
                name={data.user.name}
                value={data.user.user_sk}
              />
            )}
          </DialogContent>
        </Dialog>
      ) : null}
    </React.Fragment>
  );
};

const Attendances = ({
  scheduleID,
  setFilterType,
  setFilterStatus,
  handleFilter,
  setFilter,
  presences,
  filterStatus,
  filterType,
  onSearchJemaat,
}) => {
  const [openModal, toggleModal] = React.useState(false);

  const [qrModal, setOpenQRModal] = React.useState(null);
  const { data: worship } = useSchedule(scheduleID);

  const handleClose = () => {
    toggleModal(false);
  };

  return (
    <React.Fragment>
      <Alert severity="info">
        Tap pada nama jemaat untuk menampilkan QR Code
      </Alert>
      <TextField
        fullWidth
        placeholder="Ketik nama jemaat..."
        margin="normal"
        onChange={onSearchJemaat}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {presences.length ? (
        <List>
          {presences.map((attendance) => (
            <ListItem
              button
              key={attendance.id}
              style={{ paddingLeft: 0 }}
              onClick={() => setOpenQRModal(attendance)}
            >
              <ListItemAvatar>
                <Avatar
                  alt={attendance.user.name}
                  src={getImage(attendance.user.picture, 100)}
                />
              </ListItemAvatar>
              <ListItemText>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                  style={{ textTransform: 'uppercase' }}
                >
                  {worshipRoleMapper[attendance.attendance_role]}
                </Typography>
                <Typography variant="body1">{attendance.user.name}</Typography>
                <Typography
                  component="span"
                  variant="caption"
                  color="textSecondary"
                >
                  Status:{' '}
                  {attendance.arrived_at
                    ? `Hadir (${format(
                        new Date(attendance.arrived_at),
                        'dd MMM yyyy HH:mm',
                      )})`
                    : 'Belum Hadir'}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <Empty text="Data belum tersedia" />
      )}

      <QRDialog
        open={!!qrModal}
        isRSVP={worship.worship_type === 'RSVP'}
        handleClose={() => setOpenQRModal(null)}
        worshipDate={worship.started_at}
        data={qrModal}
      />
      <Fab
        aria-label="Filter Kehadiran"
        style={{ position: 'fixed', bottom: 30, right: 30 }}
        color="primary"
        onClick={() => {
          toggleModal(true);
        }}
      >
        <FilterListIcon />
      </Fab>
      <Dialog onClose={handleClose} open={openModal} fullWidth>
        <DialogTitle onClose={handleClose} style={{ padding: '8px 16px' }}>
          Filter Kehadiran
        </DialogTitle>
        <DialogContent dividers style={{ padding: 16 }}>
          <FormControl style={{ width: '100%', marginBottom: 16 }}>
            <InputLabel shrink>Tipe Kehadiran</InputLabel>
            <Select
              value={filterType}
              onChange={(e) => {
                setFilterType(e.target.value);
              }}
            >
              <MenuItem value="all">Semua</MenuItem>
              <MenuItem value="congregation">Jemaat</MenuItem>
              <MenuItem value="servant">Pelayan</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ width: '100%' }}>
            <InputLabel shrink>Status</InputLabel>
            <Select
              value={filterStatus}
              onChange={(e) => {
                setFilterStatus(e.target.value);
              }}
            >
              <MenuItem value="all">Semua</MenuItem>
              <MenuItem value="not_present">Belum Hadir</MenuItem>
              <MenuItem value="present">Sudah Hadir</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setFilterStatus('all');
              setFilterType('all');
              toggleModal(false);
              setFilter({
                servant: null,
                congregation: null,
                present: null,
                not_present: null,
              });
            }}
            style={{ margin: '0 8px' }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleFilter();
              handleClose();
            }}
            style={{ margin: 0 }}
          >
            Terapkan
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Attendances;
