import React from 'react';
import { Box, Fab, Tabs, Tab } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import StayPrimaryLandscapeIcon from '@mui/icons-material/StayPrimaryLandscape';

import { SecondaryNavigation } from 'components';

import Attendances from 'pages/Worship/Absent/Attendances';
import CameraScan from 'pages/Worship/Absent/CameraScan.onsite';
import Scanner from 'pages/Worship/Absent/Scanner.onsite';
import { useAbsence } from 'libs/hooks/schedules';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={2}>{children}</Box>}
    </Box>
  );
};

const Absent = ({ match }) => {
  const [filterType, setFilterType] = React.useState('all');
  const [filterStatus, setFilterStatus] = React.useState('all');
  const [filter, setFilter] = React.useState({
    servant: 0,
    congregation: 0,
    present: 0,
    not_present: 0,
  });

  const [filterName, setFilterName] = React.useState();

  const { data: presences } = useAbsence(match.params.id, filter);

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChangeTab = (_, newValue) => {
    setTabIndex(newValue);
  };

  const [phoneMode, setPhoneMode] = React.useState(true);
  const handleFilter = () => {
    setFilter({
      servant: filterType === 'servant' ? 1 : 0,
      congregation: filterType === 'congregation' ? 1 : 0,
      present: filterStatus === 'present' ? 1 : 0,
      not_present: filterStatus === 'not_present' ? 1 : 0,
    });
  };

  return (
    <React.Fragment>
      <SecondaryNavigation title="Absensi Ibadah" />
      <Box marginX="auto" maxWidth="550px">
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Absensi" />
          <Tab label={`Kehadiran (${presences.length})`} />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          {phoneMode ? (
            <CameraScan scheduleID={match.params.id} />
          ) : (
            <Scanner scheduleID={match.params.id} />
          )}
          <Fab
            aria-label="Mode Scanner"
            style={{ position: 'fixed', bottom: 30, right: 30 }}
            color="primary"
            onClick={() => {
              setPhoneMode(!phoneMode);
            }}
          >
            {!phoneMode ? <PhoneIphoneIcon /> : <StayPrimaryLandscapeIcon />}
          </Fab>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Attendances
            scheduleID={match.params.id}
            setFilterType={setFilterType}
            setFilterStatus={setFilterStatus}
            handleFilter={handleFilter}
            setFilter={setFilter}
            presences={
              filterName
                ? presences.filter(
                    (p) =>
                      p.user.name
                        .toLowerCase()
                        .indexOf(filterName.toLowerCase()) > -1,
                  )
                : presences
            }
            filterType={filterType}
            filterStatus={filterStatus}
            onSearchJemaat={(e) => setFilterName(e.target.value)}
          />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};

export default Absent;
