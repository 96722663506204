import React from 'react';
import { Box, Typography, Avatar, Button, FormHelperText } from '@mui/material';

import useQuery from 'libs/utils/query';
import { membershipMapper } from 'pages/User/utils';
import { worshipRoleMapper } from 'pages/Worship/utils';
import { useInvitationDetail } from 'libs/hooks/schedules';
import playAudio from 'libs/utils/audio';

const Scanner = ({ scheduleID }) => {
  const query = useQuery();
  const isDebug = Boolean(+query.get('debug'));

  const inputRef = React.useRef();
  const [error, setError] = React.useState();
  const [scanLoading, setScanLoading] = React.useState(false);
  const [textValue, setTextValue] = React.useState('');
  const [isInputFocused, setInputFocused] = React.useState(false);
  const [guest, setGuest] = React.useState();

  const { onArrived, loading } = useInvitationDetail(scheduleID);

  const rescan = () => {
    console.log('RESCAN');
    setTextValue('');
    setError(null);
    setGuest(null);
  };

  return (
    <React.Fragment>
      {error ? (
        <Box textAlign="center" marginTop={2}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      ) : guest && guest.user ? (
        <Box textAlign="cennter">
          <Avatar
            src={guest.user.picture}
            style={{ width: 100, height: 100, margin: 'auto' }}
          />
          <Typography variant="subtitle1" align="center">
            {guest.user.name}
          </Typography>
          <Box width="70%" margin="auto" textAlign="center">
            <Typography variant="caption">
              {worshipRoleMapper[guest.attendance_role]} |{' '}
            </Typography>
            <Typography variant="caption">{guest.user.age} tahun | </Typography>
            <Typography variant="caption">
              {membershipMapper[guest.user.membership]}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          marginTop={2}
          onClick={() => {
            rescan();
            inputRef.current.focus();
          }}
        >
          {isInputFocused ? (
            <p>
              <span style={{ color: 'green', fontSize: 22 }}>●</span> Silakan
              memulai pemindaian.
            </p>
          ) : (
            <p color="red">
              <span style={{ color: 'red', fontSize: 22 }}>●</span> Input QR
              Code belum siap digunakan.
            </p>
          )}
        </Box>
      )}
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        style={{ marginTop: 16 }}
        disabled={loading || scanLoading}
        onClick={() => {
          rescan();
          inputRef.current.focus();
        }}
      >
        {loading || scanLoading
          ? 'Loading...'
          : isInputFocused
          ? 'Reset'
          : 'Reconnect QR Reader'}
      </Button>
      <Box
        marginTop={2}
        position={isDebug ? 'relative' : 'absolute'}
        top={isDebug ? 0 : -1000}
      >
        <FormHelperText>Debug:</FormHelperText>
        <input
          autoFocus
          type="text"
          ref={inputRef}
          value={textValue}
          onChange={(e) => {
            setTextValue(e.target.value);
            setScanLoading(true);
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              try {
                const guest = await onArrived(textValue);
                if (guest) {
                  setGuest(guest);
                  playAudio('success');
                  setTimeout(() => rescan(), 3000);
                } else {
                  rescan();
                  playAudio('failed');
                }
              } catch (error) {
                playAudio('failed');
                alert(error);
              } finally {
                setScanLoading(false);
              }
            }
          }}
          onBlur={() => setInputFocused(false)}
          onFocus={() => setInputFocused(true)}
          style={{ width: '100%', padding: 8, marginTop: 8 }}
        />
      </Box>
    </React.Fragment>
  );
};

export default Scanner;
