import React from 'react';
import { paramCase } from 'change-case';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { QRCodeUser } from 'components';

const QRModal = ({ handleClose, openModal, user }) => {
  const downloadQR = React.useRef(null);

  const handleDownload = (e) => {
    e.preventDefault();
    const qrImage = document.querySelector('#qrcode > img');

    downloadQR.current.setAttribute(
      'download',
      paramCase(user.name) + '.png',
    );
    downloadQR.current.setAttribute(
      'href',
      qrImage.getAttribute('src').replace('image/png', 'image/octet-stream'),
    );
    downloadQR.current.click();
  };

  return (
    <Dialog onClose={handleClose} open={openModal} fullWidth>
      <DialogTitle onClose={handleClose} style={{ padding: '8px 16px' }}>
        QR Code Jemaat
      </DialogTitle>
      <DialogContent dividers style={{ padding: 16, textAlign: 'center' }}>
        <QRCodeUser
          name={user.name}
          value={user.user_sk}
        />
      </DialogContent>
      <DialogActions>
        <a
          id="downloadQR"
          ref={downloadQR}
          href="/#"
          style={{ display: 'none' }}
        >
          &nbsp;
        </a>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownload}
          style={{ margin: 0 }}
        >
          Download QR
        </Button>

        <Button
          onClick={() => {
            handleClose();
          }}
          style={{ margin: '0 8px' }}
        >
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRModal;
