import React from 'react';
import format from 'date-fns/format';
import {
  Avatar,
  Box,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Skeleton,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import { Loader, QRModal, SecondaryNavigation } from 'components';
import { getImage } from 'libs/utils/image';

import UserRelation from 'pages/User/Detail/Relation';
import UserAddress from 'pages/User/Detail/Address';
import UserCommision from 'pages/User/Detail/Commision';
import DeleteDialog from 'pages/User/Detail/Delete';

import { useJemaat, useJemaatDetail } from 'libs/hooks/jemaat';
import {
  genderMapper,
  maritalStatusMapper,
  membershipMapper,
} from 'pages/User/utils';

const Detail = ({ match, history }) => {
  const { onDelete } = useJemaat();
  const {
    data: user,
    loading,
    onAssignAddress,
  } = useJemaatDetail(match.params.user_sk);

  const [deleteDialog, toggleDialog] = React.useState(false);
  const [qrModalVisibility, setQRModalVisibility] = React.useState(false);
  const handleDelete = () => {
    onDelete(match.params.user_sk);
    toggleDialog(false);
  };

  if (loading) {
    return (
      <Box margin="auto" maxWidth="550px" padding="16px">
        <Skeleton variant="rectangular" width="auto" height={118} />
        <Skeleton
          variant="rectangular"
          width="auto"
          height={118}
          style={{ margin: '16px 0' }}
        />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <SecondaryNavigation title="Detail Anggota" goBackPath="/d/jemaat" />
      <Box
        sx={{
          background: "url('/pattern.png') #F3F4F5",
          overflow: 'hidden',
          minHeight: '210px',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          padding: '16px',
        }}
      >
        {user.picture ? (
          <Avatar
            style={{ width: '150px', height: '150px' }}
            src={getImage(user.picture)}
            alt={user.name}
          />
        ) : null}
      </Box>
      <Box margin="auto" maxWidth="550px">
        {loading ? <Loader /> : null}
        {user && (
          <React.Fragment>
            <List
              subheader={<ListSubheader>Data Personal</ListSubheader>}
              style={{ position: 'relative' }}
            >
              <Fab
                aria-label="Edit Anggota"
                style={{
                  position: 'absolute',
                  top: -50,
                  right: 30,
                  transform: 'translate3d(0, 50%, 0)',
                  zIndex: 1000,
                }}
                color="primary"
                onClick={() =>
                  history.push(`/d/jemaat/${match.params.user_sk}/edit`)
                }
              >
                <EditIcon />
              </Fab>
              <Fab
                aria-label="Edit Anggota"
                style={{
                  position: 'absolute',
                  top: -50,
                  right: 100,
                  transform: 'translate3d(0, 50%, 0)',
                  zIndex: 1000,
                }}
                color="primary"
                onClick={() => setQRModalVisibility(true)}
              >
                <QrCode2Icon />
              </Fab>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi">
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    Nama
                  </Typography>
                  <Typography>
                    {user.name}{' '}
                    {user.mandarin_name ? `(${user.mandarin_name})` : null}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText inset>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    Nama Panggilan
                  </Typography>
                  <Typography>{user.nickname}</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText inset>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    Jenis Kelamin
                  </Typography>
                  <Typography>{genderMapper[user.gender]}</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText inset>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    Tempat, Tanggal Lahir
                  </Typography>
                  <Typography>
                    {user.birth_place},{' '}
                    {user.date_of_birth
                      ? format(new Date(user.date_of_birth), 'dd MMM yyyy')
                      : null}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText inset>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    No. Handphone
                  </Typography>
                  <Typography>{user.phone}</Typography>
                </ListItemText>
              </ListItem>
              {user.occupation ? (
                <ListItem>
                  <ListItemText inset>
                    <Typography
                      component="span"
                      variant="caption"
                      color="textPrimary"
                    >
                      Pekerjaan
                    </Typography>
                    <Typography>{user.occupation}</Typography>
                  </ListItemText>
                </ListItem>
              ) : null}
              <ListItem>
                <ListItemText inset>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    Status Perkawinan
                  </Typography>
                  <Typography>
                    {maritalStatusMapper[user.marital_status]}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText inset>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    Status Keanggotaan
                  </Typography>
                  <Typography>{membershipMapper[user.membership]}</Typography>
                </ListItemText>
              </ListItem>
              {user.church_origin ? (
                <ListItem>
                  <ListItemText inset>
                    <Typography
                      component="span"
                      variant="caption"
                      color="textPrimary"
                    >
                      Gereja Asal
                    </Typography>
                    <Typography>{user.church_origin}</Typography>
                  </ListItemText>
                </ListItem>
              ) : null}
              {user.is_baptised ? (
                <ListItem>
                  <ListItemText inset>
                    <Typography
                      component="span"
                      variant="caption"
                      color="textPrimary"
                    >
                      Status Baptis
                    </Typography>
                    <Typography>
                      {user.is_baptised ? 'Sudah dibaptis' : 'Belum dibaptis'}
                    </Typography>

                    <Typography
                      component="span"
                      variant="caption"
                      color="textPrimary"
                    >
                      {user.baptise_church} -{' '}
                      {user.baptise_year
                        ? new Date(user.baptise_year).getFullYear()
                        : null}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ) : null}
            </List>
            <Divider variant="middle" />
            <UserAddress
              userID={user.id}
              onAssignAddress={onAssignAddress}
              address={user.address}
            />
            <Divider variant="middle" />
            <UserRelation userID={user.id} />
            <Divider variant="middle" />
            <UserCommision userID={user.id} />
          </React.Fragment>
        )}
        <Box marginBottom={6} />
        <DeleteDialog
          open={deleteDialog}
          onSelect={handleDelete}
          onClose={() => toggleDialog(false)}
          onClick={() => toggleDialog(true)}
        />
        {user ? (
          <QRModal
            handleClose={() => setQRModalVisibility(false)}
            openModal={qrModalVisibility}
            user={user}
          />
        ) : null}
      </Box>
    </React.Fragment>
  );
};

export default Detail;
