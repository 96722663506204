import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

const SecondaryMenu = ({ title, goBackPath }) => {
  const history = useHistory();
  return (
    <Box marginX="auto" marginTop={7} maxWidth="550px">
      <AppBar position="fixed">
        <Toolbar style={{ width: '100%', maxWidth: 550, margin: '0 auto' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() =>
              goBackPath ? history.push(goBackPath) : history.goBack()
            }
            size="large">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6">{title}</Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default SecondaryMenu;
