import useSWR, { mutate } from 'swr';
import debounce from 'lodash.debounce';
import format from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useState, useCallback } from 'react';

import config from 'config';
import api from 'libs/utils/api';
import useToast from 'libs/contexts/toasts';
import { getChurch } from 'libs/utils/token';

export const useJemaat = () => {
  const toast = useToast();
  const history = useHistory();
  const [cookies] = useCookies([config.COOKIE_NAME]);

  const [loading, setLoading] = useState(false);
  const pathKey = '/users/?page=1&limit=100';
  const { data = [], error, isValidating } = useSWR(() => cookies.access_token ? pathKey : null);

  const onAdd = async (data) => {
    setLoading(true);
    try {
      const churchID = getChurch(cookies.access_token);
      const dob = format(new Date(data.date_of_birth), 'yyyy-MM-dd');
      const join_date = format(new Date(data.join_date), 'yyyy-MM-dd');

      const payload = {
        church_id: churchID,
        ...data,
        join_date: join_date,
        date_of_birth: dob,
      };

      const { data: res } = await api.post('/users/', payload);
      if (res.success) {
        mutate(pathKey);
        history.push(`/d/jemaat/${res.data.user_sk}`);
        toast('success', 'Data berhasil disimpan.');
      } else {
        toast('error', 'Gagal menyimpan data.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal menyimpan data.');
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (userSK) => {
    setLoading(true);
    try {
      const { data: res } = await api.delete('/users/v2/', {
        data: [userSK],
      });

      if (res.success) {
        mutate(pathKey);
        toast('Data berhasil dihapus.');
        history.push('/d/jemaat');
      } else {
        toast('error', 'Gagal menghapus data.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal menghapus data.');
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading: (!error && !data) || isValidating || loading,
    onAdd,
    onDelete,
  };
};

export const useJemaatDetail = (userSK) => {
  const toast = useToast();
  const history = useHistory();
  const [cookies] = useCookies([config.COOKIE_NAME]);

  const [loading, setLoading] = useState(false);
  const pathKey = `/users/v2/${userSK}`;
  const {
    data,
    error,
    isValidating,
  } = useSWR(() => (userSK ? pathKey : null));

  const onUpdate = async (data) => {
    setLoading(true);
    try {
      const churchID = getChurch(cookies.access_token);
      const dob = format(new Date(data.date_of_birth), 'yyyy-MM-dd');
      const join_date = format(new Date(data.join_date), 'yyyy-MM-dd');

      const { data: res } = await api.put(`/users/v2/${userSK}`, {
        church_id: churchID,
        ...data,
        date_of_birth: dob,
        join_date,
      });
      if (res.success) {
        mutate(pathKey);
        history.push(`/d/jemaat/${res.data.user_sk}/detail`);
        toast('success', 'Data berhasil disimpan.');
      } else {
        toast('error', 'Gagal menyimpan data.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal menyimpan data.');
    } finally {
      setLoading(false);
    }
  };

  const onAssignAddress = async (userID, addressID) => {
    setLoading(true);
    try {
      const { data: res } = await api.post(`/users/${userID}/address`, {
        address_id: addressID,
      });

      if (res.success) {
        mutate(pathKey);
        toast('success', 'Data berhasil disimpan.');
      } else {
        toast('error', 'Gagal menyimpan data.');
      }
    } catch (error) {
      console.error(error);
      toast('error', 'Gagal menyimpan data.');
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading: (!error && !data) || isValidating || loading,
    onUpdate,
    onAssignAddress,
  };
};

export const useSearchJemaat = () => {
  const [options, setOptions] = useState([]);

  const fetchData = debounce((name) => {
    api
      .get(`/users/?name=${name}&page=1&limit=100`)
      .then(({ data: res }) => {
        setOptions(res.data);
      })
      .catch(() => setOptions([]));
  }, 400);

  const handleSearchUser = useCallback(
    (query) => {
      if (query) {
        fetchData(query);
      } else {
        setOptions([]);
      }
    },
    [fetchData],
  );

  return { data: options, search: handleSearchUser };
};
