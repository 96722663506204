import React from 'react';
import QrReader from 'react-qr-reader';
import { Box, Typography, Avatar, Button, FormHelperText } from '@mui/material';

import { membershipMapper } from 'pages/User/utils';
import { worshipRoleMapper } from 'pages/Worship/utils';
import { useInvitationDetail } from 'libs/hooks/schedules';
import playAudio from 'libs/utils/audio';

const facingMode = {
  true: 'environment',
  false: 'user',
};

const CameraScan = ({ scheduleID }) => {
  const [camera, switchCamera] = React.useState(true);
  const [error, setError] = React.useState();
  const [guest, setGuest] = React.useState(null);
  const { loading, onArrived } = useInvitationDetail(scheduleID);

  const rescan = () => {
    setError(null);
    setGuest(null);
  };

  return (
    <React.Fragment>
      {error ? (
        <React.Fragment>
          <Box textAlign="center" marginTop={2}>
            <FormHelperText error>{error}</FormHelperText>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            style={{ marginTop: 16 }}
            onClick={rescan}
          >
            Scan Ulang
          </Button>
        </React.Fragment>
      ) : guest && guest.user ? (
        <Box textAlign="center">
          <Avatar
            src={guest.user.picture}
            style={{ width: 100, height: 100, margin: 'auto' }}
          />
          <Typography variant="subtitle1">{guest.user.name}</Typography>
          <Box width="70%" margin="auto">
            <Typography variant="caption">
              {worshipRoleMapper[guest.attendance_role]} |{' '}
            </Typography>
            <Typography variant="caption">{guest.user.age} tahun | </Typography>
            <Typography variant="caption">
              {membershipMapper[guest.user.membership]}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            style={{ marginTop: 16 }}
            onClick={rescan}
          >
            Scan Ulang
          </Button>
        </Box>
      ) : (
        <React.Fragment>
          <QrReader
            delay={2000}
            onError={(err) => setError(err.toString())}
            facingMode={facingMode[camera]}
            onScan={async (val) => {
              if (loading) return;

              if (val) {
                const guest = await onArrived(val);

                if (guest) {
                  playAudio('success');
                  setGuest(guest);
                  setTimeout(() => rescan(), 3000);
                } else {
                  playAudio('failed');
                  rescan();
                }
              }
            }}
            style={{ width: '100%' }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: 16 }}
            onClick={() => {
              switchCamera(!camera);
            }}
          >
            Ganti Mode Kamera
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CameraScan;
